import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { RestApiService } from "./restApi.service";
import { EstadoCivil } from '@interfaces/estadoCivil';

@Injectable({
  providedIn: 'root'
})

export class EstadoCivilService extends RestApiService {
  getAll(): Observable<EstadoCivil[]> {
    return this.http.get<EstadoCivil[]>(this.apiURL + '/EstadoCivil')
    .pipe(
      map(res => {return res;}),
      retry(1),
      catchError(this.handleError)
    )
  }
}
