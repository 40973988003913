<div class="demo-container p-p-3">
	<p class="sub-titulo">Hijos estudiando fuera de la provincia</p>
	<div class="example-form">
		<div class="p-d-flex">
			<div class="p-mr-2 p-as-center">
				<button pButton type="button" class="p-button-raised p-button-rounded" icon="pi pi-plus"
					(click)="addEstudiante()"></button>
			</div>
		</div>
		<br>
		<table mat-table *ngIf="estudientes.length > 0" [dataSource]="estudientes" class="mat-elevation-z8">
			<tr mat-header-row *matHeaderRowDef="columns"></tr>
			<tr mat-row *matRowDef="let row; columns: columns;"></tr>

			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef> No. </th>
				<td mat-cell *matCellDef="let element"> {{element.id}} </td>
			</ng-container>

			<ng-container matColumnDef="nombre">
				<th mat-header-cell *matHeaderCellDef> Nombre </th>
				<td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
			</ng-container>

			<ng-container matColumnDef="apellido">
				<th mat-header-cell *matHeaderCellDef> Apellido </th>
				<td mat-cell *matCellDef="let element"> {{element.apellido}} </td>
			</ng-container>

			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let element">
					<button mat-button color="primary" (click)="click_remove(element)" >
						<mat-icon>remove</mat-icon>
					</button>
				</td>
			</ng-container>
		</table>
	</div>
</div>
