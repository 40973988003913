<p>Se deberá utilizar un único registro de contacto por grupo familiar, realizando todas las pre-inscripciones necesarias en el mismo</p>
<p style="color: #4471d7;">formulario clickeando el botón <b>Agregar Nuevo Postulante </b> para agregar nuevas solicitudes por postulante y nivel.</p>
<div class="demo-container p-p-3">
	<p class="sub-titulo">Postulante</p>
	<div class="example-form">
		<div class="p-d-flex">
			<div class="p-mr-2 p-as-center">
				<label for="educacion">Educación </label>
				<p-dropdown (onChange)="selectEducacion($event.value)" [options]="educaciones" optionLabel="nombre"
					placeholder="Seleccionar">
				</p-dropdown>
			</div>
			<div class="p-mr-2 p-as-center">
				<button mat-raised-button color="primary" [disabled]="!selectedEducacion"  (click)="addPostulante()">Agregar Nuevo Postulante</button>
			</div>
		</div>
		<br>
		<table mat-table *ngIf="postulantes.length > 0" [dataSource]="postulantes" class="mat-elevation-z8" style="width: 100%;">
			<tr mat-header-row *matHeaderRowDef="columns"></tr>
			<tr mat-row *matRowDef="let row; columns: columns;"></tr>

			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef> No. </th>
				<td mat-cell *matCellDef="let element"> {{element.id}} </td>
			</ng-container>

			<ng-container matColumnDef="dni">
				<th mat-header-cell *matHeaderCellDef> DNI </th>
				<td mat-cell *matCellDef="let element"> {{element.documento}} </td>
			</ng-container>

			<ng-container matColumnDef="nombre">
				<th mat-header-cell *matHeaderCellDef> Nombre </th>
				<td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
			</ng-container>

			<ng-container matColumnDef="apellido">
				<th mat-header-cell *matHeaderCellDef> Apellido </th>
				<td mat-cell *matCellDef="let element"> {{element.apellido}} </td>
			</ng-container>

			<ng-container matColumnDef="nivel">
				<th mat-header-cell *matHeaderCellDef> Nivel </th>
				<td mat-cell *matCellDef="let element"> {{element.niveleducativo}} </td>
			</ng-container>

			<ng-container matColumnDef="actionEdit">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let element">
					<button mat-button color="primary" (click)="click_edit(element)" >
						Editar
					</button>
				</td>
			</ng-container>

			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let element">
					<button mat-button color="warn" (click)="click_remove(element)" >
						Eliminar
					</button>
				</td>
			</ng-container>

		</table>
	</div>
</div>
