import { SectorEducativo } from '@interfaces/sectorEducativo';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError,map } from 'rxjs/operators';
import { Contacto } from '@interfaces/contacto';
import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class SectorEducativoService extends RestApiService {
  getAll(): Observable<SectorEducativo[]> {
    return this.http.get<SectorEducativo[]>(this.apiURL + '/sectorEducativo')
    .pipe(
      map(res => {return res;}),
      retry(1),
      catchError(this.handleError)
    )
  }
}
