import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError,map } from 'rxjs/operators';
import { RestApiService } from "./restApi.service";
import { NivelEducativo } from '../interfaces/nivelEducativo';

@Injectable({
  providedIn: 'root'
})

export class NivelEducativoService extends RestApiService {
  getAll(): Observable<NivelEducativo[]> {
    return this.http.get<NivelEducativo[]>(this.apiURL + '/nivelEducativo')
    .pipe(
      map(resp => {return resp;}),
      retry(1),
      catchError(this.handleError)
    )
  }
}
