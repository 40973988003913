import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import { RestApiService } from "./restApi.service";
import { Escuela } from '@interfaces/escuela';

@Injectable({
  providedIn: 'root'
})

export class EscuelaService extends RestApiService {

  getAllByLocalidadByNivel(localidadId:number, nivelId: number): Observable<Escuela[]> {
    return this.http.get<Escuela[]>(this.apiURL + '/Escuela/getAllByLocalidadByNivel/' + localidadId+"/"+ nivelId)
    .pipe(
      map(res =>{return res}),
      retry(1),
      catchError(this.handleError)
    )
  }
}
