import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Estudiante } from "@interfaces/estudiante";
import { Pais } from "@interfaces/pais";
import { Provincia } from "@interfaces/provincia";

import {Localidad} from "@interfaces/localidad";
import {Inmobiliaria} from "@interfaces/inmobiliaria";

import {PaisService} from "@api/pais.service";
import {ProvinciaService} from  "@api/provincia.service";
import {LocalidadService} from "@api/localidad.service";
import {InmobiliariaService} from "@api/inmobiliaria.service";
@Component({
	selector: 'form-estudianteOutside',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.css'],
	providers: [
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: { hasBackdrop: true, width: "50vw" },
		},
	],
})
export class EstudianteOutsideFormComponent implements OnInit {

	form: FormGroup;
	paises: Pais[];
	provincias: Provincia[];
	localidades: Localidad[];
	inmobiliarias: Inmobiliaria[];

	constructor(
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: Estudiante,
    private paisService:PaisService,
    private provinciaService:ProvinciaService,
    private localidadService:LocalidadService,
    private inmobiliariaService:InmobiliariaService,
	) { }

	ngOnInit(): void {
    this.paisService.getAll().subscribe(resp => {
      this.paises = resp;

      this.inmobiliariaService.getAll().subscribe(resp => {
        this.inmobiliarias = resp;
      });
    });
		this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group({
			nombre: [this.data?.nombre, [Validators.required, Validators.maxLength(50)]],
			apellido: [this.data?.apellido, [Validators.required, Validators.maxLength(50)]],
      documento: [this.data?.documento, [Validators.required, Validators.maxLength(8)]],
			instituto: [this.data?.instituto, Validators.maxLength(50)],
			localidad: [this.data?.localidad, Validators.required],
			inmobiliaria: [this.data?.inmobiliaria, Validators.required],
			montoAlquiler: [this.data?.montoAlquiler, Validators.required],
			otrasConsideraciones: [this.data?.consideraciones, [Validators.required, Validators.maxLength(100)]],
		});
	}

	selectPais(value: Pais) {
    this.provinciaService.getAllByPais(value.id).subscribe(resp => {
      this.provincias = resp;
    });
	}

	selectProvincia(value: Provincia) {
    this.localidadService.getAllByProvincia(value.id).subscribe(resp => {
      this.localidades = resp;
    });
	}

	getFormValue(): Estudiante {

		return {
			...this.form.value
		};
	}
}
