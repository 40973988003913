<div class="demo-container p-p-3">
	<h3 style="color: #3d73d5;font-style: oblique;font-weight: bold;">En caso de postulante mayor de edad, completar con tus propios datos.</h3>
	<p class="sub-titulo">Tutor</p>
	<form [formGroup]="form" class="form-container" autocomplete="off">
		<mat-form-field class="example-full-width">
			<mat-label>Nombre <span> * </span></mat-label>
			<input type="text" #nombre maxlength="50" matInput formControlName="nombre" />
			<mat-hint align="end">{{nombre.value?.length || 0}}/50</mat-hint>
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Apellido <span> * </span></mat-label>
			<input type="text" #apellido maxlength="50" matInput formControlName="apellido" />
			<mat-hint align="end">{{apellido.value?.length || 0}}/50</mat-hint>
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Documento <span> * </span></mat-label>
			<input type="number" #documento maxlength="50" matInput formControlName="documento" />
			<mat-hint align="end">{{documento.value?.length || 0}}/8</mat-hint>
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Estado Civil <span> * </span></mat-label>

			<select matNativeControl  formControlName="estadoCivil" style="text-transform: uppercase;">
				<option *ngFor="let estado of estadoscivil" [value]="estado.id">
				  {{ estado.nombre }}
				</option>
			  </select>

		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Pais <span> * </span></mat-label>

			<select matNativeControl  formControlName="pais" (change)="selectPais($event.target.value)" style="text-transform: uppercase;">
				<option *ngFor="let pais of paises" [value]="pais.id">
				  {{ pais.nombre }}
				</option>
			  </select>
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Provincia <span> * </span></mat-label>

			<select matNativeControl  formControlName="provincia" (change)="selectProvincia($event.target.value)" style="text-transform: uppercase;">
				<option value="0">Seleccionar</option>
			<option *ngFor="let provincia of provincias" [value]="provincia.id">
			  {{ provincia.nombre }}
			</option>
		  </select>

		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Ciudad <span> * </span></mat-label>

			<select matNativeControl  formControlName="ciudad"  style="text-transform: uppercase;">
				<option value="0">Seleccionar</option>
				<option *ngFor="let ciudad of ciudades" [value]="ciudad.id">
				  {{ ciudad.nombre }}
				</option>
			  </select>

		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Domicilio <span> * </span></mat-label>
			<input type="text" #domicilio maxlength="50" matInput formControlName="domicilio" />
			<mat-hint align="end">{{domicilio.value?.length || 0}}/80</mat-hint>
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Teléfono <span> * </span></mat-label>
			<input type="text" #telefono matInput formControlName="telefono" />
			<mat-hint align="end">{{telefono.value?.length || 0}}/12</mat-hint>
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Ocupación <span> * </span></mat-label>
			<input type="text" #ocupacion maxlength="50" matInput formControlName="ocupacion" />
			<mat-hint align="end">{{ocupacion.value?.length || 0}}/80</mat-hint>
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Correo <span> * </span></mat-label>
			<input type="email" #correo maxlength="50" matInput formControlName="correo" />
		</mat-form-field>

		<br>
	</form>
</div>
