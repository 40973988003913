import { Injectable } from '@angular/core';
import { Condicional } from '@interfaces/Condicional';
import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class CondicionalService extends RestApiService {

  getAll(): Condicional[] {
    let c =  [
      {
        id: 1,
		    nombre: "NO",
        status: false,
      },
      {
        id: 2,
		    nombre: "SI",
        status: true,
      }
    ];
    return c;
  }
}
