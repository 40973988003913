import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// Api
import { PostulanteService } from "@api/postulante.service";

// Forms
import { FamiliaFormComponent } from "./grupofamiliar/form/form.component";
import { EstudianteOutsideFormComponent } from "./estudianteoutside/form/form.component";
import { InicialFormComponent } from "./postulante/forms/inicial/inicial.component";
import { PrimariaFormComponent } from "./postulante/forms/primaria/primaria.component";
import { SecundariaFormComponent } from "./postulante/forms/secundaria/secundaria.component";
import { SuperiorFormComponent } from "./postulante/forms/superior/superior.component";

// Component
import { AppComponent } from './app.component';
import { ContactoComponent } from './contacto/contacto.component';
import { PostulanteComponent } from './postulante/postulante.component';
import { DynamicHostDirective } from './directive/dynamic-host.directive';
//import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


import { GratulationComponent } from './gratulation/gratulation.component';
import { TutorComponent } from './tutor/tutor.component';
import { ComentarioComponent } from './comentario/comentario.component';
import { GrupoFamiliarComponent } from './grupofamiliar/grupofamiliar.component';
import { SocioEconomicoComponent } from './socioeconomico/socioeconomico.component';
import { EstudianteOutsideComponent } from './estudianteoutside/estudianteoutside.component';

import {ScrollPanelModule} from 'primeng/scrollpanel';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatStepperModule } from '@angular/material/stepper';
import { MatNativeDateModule } from '@angular/material/core';
import { DialogMensajeComponent } from './dialogmensaje/dialogmensaje.component';
import { EmailComponent } from './form-field/email/email.component';
import { TerminosComponent } from './terminos/terminos.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { UploadComponent } from './upload/upload.component';
import { MatListModule } from '@angular/material/list';

import { MomentDateModule } from '@angular/material-moment-adapter';
import { LoginComponent } from './login/login.component';
import { ReportComponent } from './report/report.component';
import { DialogRegister, DialogResetpassword, RealLoginComponent } from './RealLogin/Reallogin.component';
import { APP_ROUTES, RouterService } from './router/router.service';

@NgModule({
  declarations: [
    AppComponent,
    ContactoComponent,
    PostulanteComponent,
    DynamicHostDirective,
    GratulationComponent,
    TutorComponent,
    ComentarioComponent,
    GrupoFamiliarComponent,
    SocioEconomicoComponent,
    EstudianteOutsideComponent,
    RealLoginComponent,

    InicialFormComponent,
    PrimariaFormComponent,
    SecundariaFormComponent,
    SuperiorFormComponent,

    FamiliaFormComponent,
    EstudianteOutsideFormComponent,
    DialogMensajeComponent,
    EmailComponent,
    TerminosComponent,
    UploadComponent,
    LoginComponent,
    ReportComponent,
    DialogRegister,
    DialogResetpassword,
  ],
  
  imports: [
    APP_ROUTES,
    FormsModule,
    BrowserModule,
    //NoopAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,

    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    CascadeSelectModule,
    DropdownModule,
    ButtonModule,
    ScrollPanelModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    MatListModule,
    MomentDateModule,
    MatRadioModule
  ],
  providers: [RouterService],
  bootstrap: [AppComponent]
})
export class AppModule { }
