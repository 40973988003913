<h2 mat-dialog-title>
	DATOS ESTUDIANTE
</h2>

<mat-dialog-content>
	<form [formGroup]="form"  autocomplete="off">
		<br>
		<mat-card class="mat-elevation-z8">
			<mat-card-content>
				<mat-form-field class="example-full-width">
					<mat-label>Nombre <span> * </span></mat-label>
					<input type="text" #nombre maxlength="50" matInput formControlName="nombre" />
					<mat-hint align="end">{{nombre.value?.length || 0}}/50</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Apellido <span> * </span></mat-label>
					<input type="text" #apellido maxlength="50" matInput formControlName="apellido" />
					<mat-hint align="end">{{apellido.value?.length || 0}}/50</mat-hint>
				</mat-form-field>

        <mat-form-field class="example-full-width">
					<mat-label>Documento <span> * </span></mat-label>
					<input type="text" #documento maxlength="50" matInput formControlName="documento" />
					<mat-hint align="end">{{documento.value?.length || 0}}/8</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Instituto <span> * </span></mat-label>
					<input type="text" #instituto maxlength="50" matInput formControlName="instituto" />
					<mat-hint align="end">{{instituto.value?.length || 0}}/50</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Pais <span> * </span></mat-label>
					<mat-select id="pais" (selectionChange)="selectPais($event.value)">
						<mat-option [value]="pais" *ngFor="let pais of paises">{{ pais.nombre }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Provincia <span> * </span></mat-label>
					<mat-select id="provincia" (selectionChange)="selectProvincia($event.value)">
						<mat-option [value]="provincia" *ngFor="let provincia of provincias">{{ provincia.nombre }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Ciudad <span> * </span></mat-label>
					<mat-select id="localidad" formControlName="localidad">
						<mat-option [value]="localidad" *ngFor="let localidad of localidades">{{ localidad.nombre }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Vivienda <span> * </span></mat-label>
					<mat-select id="inmobiliaria" formControlName="inmobiliaria">
						<mat-option [value]="inmobiliaria" *ngFor="let inmobiliaria of inmobiliarias">{{ inmobiliaria.nombre }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Monto Alquiler<span> * </span></mat-label>
					<input type="number" #montoAlquiler matInput formControlName="montoAlquiler" />
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Otra Consideracion <span> * </span></mat-label>
					<textarea #otrasConsideraciones maxlength="100" matInput formControlName="otrasConsideraciones"></textarea>
					<mat-hint align="end">{{otrasConsideraciones.value?.length || 0}}/100</mat-hint>
				</mat-form-field>

			</mat-card-content>
		</mat-card>
		<br>
	</form>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" [disabled]="!this.form.valid" [mat-dialog-close]="getFormValue()">
		Aceptar
	</button>
	<button mat-raised-button color="warn" mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
