import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SectorEducativo } from '@interfaces/sectorEducativo';
import { Localidad } from './../../../interfaces/localidad';
import { Postulante } from "@interfaces/postulante";
import { Pais } from "@interfaces/pais";
import { Provincia } from "@interfaces/provincia";
import { Educacion } from "@interfaces/educacion";
import { Ciudad } from "@interfaces/ciudad";
import { Escuela } from "@interfaces/escuela";
import { Condicional } from "@interfaces/Condicional";

import {PaisService} from "@api/pais.service"
import {ProvinciaService} from "@api/provincia.service";
import {LocalidadService} from "@api/localidad.service";
import {SectorEducativoService} from "@api/sectorEducativo.service";
import {EscuelaService} from "@api/escuela.service";
import {CondicionalService} from "@api/condicional.service"
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_DATE_FORMATS } from "src/app/common/DATE_FORMATS";

@Component({
	selector: 'form-inicial',
	templateUrl: './inicial.component.html',
	styleUrls: ['./inicial.component.css'],
	providers: [
		{provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true, width: "50vw" }},
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
	],
})
export class InicialFormComponent implements OnInit {

	form: FormGroup;
	paises: Pais[];
	provincias: Provincia[];
	ciudades: Localidad[];
	servicios: SectorEducativo[];
	escuelas: Escuela[];
	salas: Array<any> = [] = [{value:"4",text:"4"},{value:"5",text:"5"}];
	selectedsala: any;
	condicionales:Condicional[] = [];
	condicional:Condicional;
	beneficiarioanterior: any;
	responsabilidadparental: any;

	constructor(
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: { postulante: any, educacion: Educacion },
    private paisService: PaisService,
    private provinciaService: ProvinciaService,
    private localidadService: LocalidadService,
    private sectorEducativoService: SectorEducativoService,
    private escuelaService: EscuelaService,
    private condicionalService: CondicionalService
	) { }

	ngOnInit(): void {
		this.paisService.getAll().subscribe(res => {
      this.paises= res;
      this.condicionales = this.condicionalService.getAll();
		this.escuelas = [];
		if (this.data.postulante != null) this.filldropdowns();
    });
    this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group({
			nombre: [this.data.postulante?.nombre, [Validators.required, Validators.maxLength(50)]],
			apellido: [this.data.postulante?.apellido, [Validators.required, Validators.maxLength(50)]],
			documento: [this.data.postulante?.documento, [Validators.required, Validators.maxLength(8)]],
			cuil: [this.data.postulante?.cuil, [Validators.required, Validators.maxLength(13)]],
			fechaNacimiento: [this.data.postulante?.fechanacimiento, Validators.required],
			lugarNacimiento: [this.data.postulante?.lugarnacimiento, Validators.maxLength(100)],
			domicilio: [this.data.postulante?.domicilio, [Validators.required, Validators.maxLength(80)]],
			nacionalidad: [this.data.postulante?.nacionalidad, [Validators.required, Validators.maxLength(80)]],
			ciudad: [this.data.postulante?.ciudad, Validators.required],
			añosResidencia: [this.data.postulante?.añosresidencia, Validators.required],
      		obraSocial: [this.data.postulante?.obrasocial],
			beneficio: [this.data.postulante?.beneficio],
			escuela: [this.data.postulante?.escuela, Validators.required],
			sala: [this.data.postulante?.sala, Validators.required],
			estudiaDistancia: false,
			beneficiarioanterior: [this.data.postulante?.beneficiarioanterior, Validators.required],
			responsabilidadparental: [this.data.postulante?.responsabilidadparental, Validators.required],
		});
		debugger;
		/*if (this.data.postulante?.sala != null && this.data.postulante?.sala != undefined)
			this.selectedsala = this.salas.find(x=> x.value == this.data.postulante?.añosestudios.toString())*/

	}

	selectPais(value: Pais) {
    this.provinciaService.getAllByPaisByCodigo(value.id,'AR-V').subscribe(resp => {
      this.provincias = resp;
    });
	}


	rebuilddropdown(name,value)
	{
	  let paiselement = (document.getElementById(name) as HTMLElement)
  
	  let paisparent = paiselement.parentNode as HTMLElement;
  
	  let start = paisparent.className.lastIndexOf("ng-tns");
	  let end = paisparent.className.toString().length;
  
	  let classnameremove =   paisparent.className.substring(start,end);
  
	  let elementtoremove = "mat-form-field-label-wrapper" + " " + classnameremove
  
	  const elementstoremove = Array.from(document.getElementsByClassName(elementtoremove) as HTMLCollectionOf<HTMLElement>)
  
	  for (const el of elementstoremove) {
		//el.remove();
		debugger
		let elemntc =  el.childNodes[0] as HTMLElement;
		elemntc.className = elemntc.className.replace("mat-empty mat-form-field-empty","");
		elemntc.className = elemntc.className.replace("mat-form-field-label","");
		let elemntd =  elemntc.childNodes[1] as HTMLElement;
		elemntd.style.position = "absolute"
		elemntd.style.marginTop = "-12px"
		elemntd.style.color = "#aeabab"
		elemntd.style.fontSize = "11px"
	  }
  
	  //debugger
	  let elementvalor = paiselement.childNodes[0].childNodes[0].childNodes[0] as HTMLElement
  
	  if (elementvalor.className != undefined)
	  {
		elementvalor.className = elementvalor.className.replace("mat-select-placeholder","");
		elementvalor.innerHTML = value
	  }
	  else
	  {
		elementvalor = paiselement.childNodes[0].childNodes[0].childNodes[1].childNodes[0].childNodes[0] as HTMLElement
		elementvalor.nodeValue = value
	  }
  
	} 
  
	filldropdowns()
	{
	  this.selectCiudad(this.data.postulante.ciudad);
	  this.rebuilddropdown("ciudad",this.data.postulante.ciudad.nombre)
	  this.getProvinciaByCiudad(this.data.postulante.ciudad);
  
	  this.rebuilddropdown("condicional",this.data.postulante.beneficio != ""? "SI":"NO")
	  this.rebuilddropdown("escuela",this.data.postulante.escuela.nombre)
  
	  if  ((this.data.postulante.beneficio != "")  || (this.data.postulante.beneficio == null))
	  {
		this.condicional = { id: 2, nombre: "SI",status: true,}
		this.form.controls['beneficio'].setValue(this.data.postulante.beneficio)
	  }
	}
  
  
	getProvinciaByCiudad(value: Ciudad) {
	  this.provinciaService.getProvinciaByCiudad(value.id).subscribe(resp => {
		debugger;
		  this.getPaisByProvincia(resp);
		  this.rebuilddropdown("provincia",resp.nombre)
	  });
	}
  
	getPaisByProvincia(value: Provincia) {
	  this.paisService.getPaisByProvincia(value.id).subscribe(resp => {
		this.rebuilddropdown("pais",resp.nombre)
	  });
	}
  
	selectbeneficiario(value:Condicional){
	  if(value.nombre.toUpperCase() == 'NO')
		  this.form.controls['beneficio'].setValue(null)
	}


	selectProvincia(value: Provincia) {
    this.localidadService.getAllByProvincia(value.id).subscribe(res => {
      this.ciudades = res;
    });
	}
  selectCiudad(value: Ciudad){
    this.escuelaService.getAllByLocalidadByNivel(value.id,this.data.educacion.id).subscribe(resp => {
      this.escuelas = resp;
    });
  }

  

	getFormValue(): Postulante {
		const { fechaNacimiento } = this.form.value;
    const { beneficio } = this.form.value;
    const { obraSocial } = this.form.value;

		return {
			...this.form.value,
			fechaNacimiento: new Date(fechaNacimiento).toISOString(),
      obraSocial : obraSocial === null ? "" : obraSocial,
      beneficio : beneficio === null ? "" : beneficio,
      sueldo: 0,
      nivelEducativo: this.data.educacion.nombre,
	  escuela:this.form.getRawValue()['escuela'],
      ciudad:this.form.getRawValue()['ciudad'],
		};
	}
}
