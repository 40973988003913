import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError,map } from 'rxjs/operators';
import { Servicio } from '@interfaces/servicio';
import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class ServicioService extends RestApiService {
  getAll(): Observable<any> {
    return this.http.get<Servicio>(this.apiURL + '/servicio')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
}
