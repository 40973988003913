import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
@Component({
  selector: 'app-dialogmensaje',
  templateUrl: './dialogmensaje.component.html',
  styleUrls: ['./dialogmensaje.component.css']
})
export class DialogMensajeComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { data: any },
  ) { }

  ngOnInit(): void {
  }

}
