import { ReportDto } from './../interfaces/reportDto';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportService } from "@api/report.service";
import { InscripcionService } from "@api/inscripcion.service"
import { Inscripcion } from '@interfaces/Inscripcion';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  options: FormGroup;
  floatLabelControl = new FormControl('CONTACTO');
  inputValue:string;
  outputValue:string;
  isResult:boolean=false;
  isSendMailAdjunto: boolean = false;
  reportes:any[] = [];
  constructor(
    fb: FormBuilder,
    private reportService: ReportService,
    private inscripcionService: InscripcionService) {
      this.options = fb.group({
        floatLabel: this.floatLabelControl,
        inputValue: [this.inputValue,[Validators.required, Validators.minLength(3)]],
        outputValue: [this.outputValue]
      });
     }

  ngOnInit(): void {
  }
  onClick() : void{
    let report: ReportDto = {documento:'',email:'',userid:'', filtro:''}
    let data = this.getFormValue();
    switch (data.floatLabel) {
      case 'CONTACTO':
        report.email = data.inputValue;
      break;
      case 'TUTOR':
        report.documento = data.inputValue;
      break;
      case 'INSCRIPCION':
        report.userid = data.inputValue;
      break;
      case 'POSTULANTE':
        report.documento = data.inputValue;
      break;
      default:
        return;
    }
    debugger
    report.filtro = data.floatLabel;
    this.reportService.getReport(report).subscribe(resp => {
      if (resp){
        this.reportes= resp;
        this.isResult = true;
        this.isSendMailAdjunto = false;
      }
      else{
        this.isResult = false;
        alert('No se encontro resultado');
      }
    });
  }
  getFormValue(): any {

		return {
			...this.options.value
		};
	}
  click_SendMailAdjunto(inscripcion: any):void{
    this.isSendMailAdjunto = true;
    //debugger
    let data = this.getFormValue();
    this.inscripcionService.sendAdjuntoByUserId(data.inputValue).subscribe(resp => {
      alert(resp.mensaje);
      this.isSendMailAdjunto = false;
    });
  }
  click_ChangeMail(token: string, inputMail: string):void {
    let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    //Se muestra un texto a modo de ejemplo, luego va a ser un icono
    if (!emailRegex.test(inputMail)) {
      alert("Correo invalido");
      return;
    }

    this.inscripcionService.changeMail(token, inputMail).subscribe(resp => {
      alert(resp.mensaje);
    });
  }
  onClick_download(inscripcion:any):void{
    //debugger
    let data = this.getFormValue();
    this.inscripcionService.downloadZipByToken(data.inputValue);
    alert('Decarga iniciada para ' + data.inputValue);
  }
}
