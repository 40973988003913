import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError,map } from 'rxjs/operators';
import { Inmobiliaria } from '@interfaces/inmobiliaria';
import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class InmobiliariaService extends RestApiService {
  getAll(): Observable<any> {
    return this.http.get<Inmobiliaria>(this.apiURL + '/inmobiliaria')
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
}
