import { Familia } from '@interfaces/familia';
import { Tutor } from '@interfaces/tutor';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Inmobiliaria } from '@interfaces/inmobiliaria';
import { SocioEconomico } from "@interfaces/socioEconomico";
import { Servicio } from "@interfaces/servicio";

import { LocalStorageService } from "../common/localStorage.service";
import { SocioEconomicoService } from "@api/socioEconomico.service";
import { INMOBILIARIA, SERVICIOS } from "@api/DATA";

import {InmobiliariaService} from "@api/inmobiliaria.service";
import {ServicioService} from "@api/servicio.service";


@Component({
	selector: 'app-socioeconomico',
	templateUrl: './socioeconomico.component.html',
	styleUrls: ['./socioeconomico.component.css']
})
export class SocioEconomicoComponent implements OnInit {

	data: SocioEconomico;
	form: FormGroup;
	inmobiliarias: Inmobiliaria[];
  inmobiliaria:Inmobiliaria;
	servicios: Servicio[];
  socioloaded: SocioEconomico = null;

  valueComponent:string = "comp_socioeconomico";
  keyComponent:string = "key_component";

  keySocioEconomico:string = "key_socioEconomico";
  keyTutor:string = "key_tutor";
  keyFamiliar:string = "key_Familiar";

	constructor(
		private socioEconomicoService: SocioEconomicoService,
    private localStorageService:LocalStorageService,
		private fb: FormBuilder,
    private inmobiliariaService: InmobiliariaService,
    private servicioService: ServicioService
	) { }

	ngOnInit(): void {
     this.localStorageService.setItem(this.keyComponent, this.valueComponent);

    this.inmobiliariaService.getAll().subscribe(resp => {
      this.inmobiliarias = resp;

      this.servicioService.getAll().subscribe(resp => {
        this.servicios = resp;

        // set Default
        this.form.controls.montoAlquiler.setValue(0);
        this.form.controls.ingresoGFC.setValue(0);
        this.form.controls.ingresoTutor.setValue(0);
        this.form.controls.ingresoGFCO.setValue(0);
        this.form.controls.ingresoExtra.setValue(0);


        let user = JSON.parse(localStorage.getItem("key_login"));
        this.socioEconomicoService.getSocioEconomicoByUser(user.id).subscribe(res => {
          if (res != null)
          {
              debugger
              let c = res;

              this.form.controls.montoAlquiler.setValue(c.montoAlquiler);
              this.form.controls.inmobiliaria.setValue(c.inmobiliaria.id);

              let arr = c.tipoServicio.split(',');

              const results = arr.map(element => {
                return element.trim();
              });
              
              

              this.form.controls.tipoServicio.setValue(results);

              this.form.controls.otrosBienes.setValue(c.otrosBienes);
              this.form.controls.vehiculo.setValue(c.vehiculo);
              //this.form.controls.ingresoGFC.setValue(c.ingresoGFC);
              this.form.controls.ingresoTutor.setValue(c.ingresoTutor);
              this.form.controls.ingresoGFCO.setValue(c.ingresoGFCO);
              this.form.controls.ingresoExtra.setValue(c.ingresoExtra);

              let ls_ingreso_gf = <Familia[]>JSON.parse(this.localStorageService.getItem(this.keyFamiliar));
              let suma_gf = 0;
              if (ls_ingreso_gf){
                ls_ingreso_gf.forEach (function(numero){suma_gf += numero.ingreso;});
                this.form.controls.ingresoGFC.setValue(suma_gf);
              }

              this.socioloaded = c;

          }
          else
          {
            debugger;
            //let ls_ingreso_tutor = <Tutor>JSON.parse(this.localStorageService.getItem(this.keyTutor));
            let ls_ingreso_gf = <Familia[]>JSON.parse(this.localStorageService.getItem(this.keyFamiliar));

            //this.form.controls.ingresoTutor.setValue(ls_ingreso_tutor ? ls_ingreso_tutor.ingreso : 0);
            let suma_gf = 0;

            if (ls_ingreso_gf){
              ls_ingreso_gf.forEach (function(numero){suma_gf += numero.ingreso;});
              this.form.controls.ingresoGFC.setValue(suma_gf);
            }
          }
        
          });

      });
    });

		this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group({
      inmobiliaria: [this.data?.inmobiliaria.id, Validators.required],
			montoAlquiler: [this.data?.montoAlquiler, Validators.required],
			tipoServicio: [this.data?.tipoServicio, Validators.required],
			otrosBienes: [this.data?.otrosBienes, Validators.maxLength(50)],
			vehiculo: [this.data?.vehiculo],
			ingresoGFC: [this.data?.ingresoGFC, Validators.required],
			ingresoTutor: [this.data?.ingresoTutor, Validators.required],
      ingresoGFCO: [this.data?.ingresoGFCO, Validators.required],
			ingresoExtra: [this.data?.ingresoExtra, Validators.required]
		});
	}

	getFormValue(): SocioEconomico{
		return {
			...this.form.value
		};
	}

  getCiudadByFilter(id):any{
    return this.inmobiliarias.filter(x => x.id === (id*1));
  }

  validate(): Observable<any> {

    debugger
    let socioEconomico:SocioEconomico = this.getFormValue();
    socioEconomico.inmobiliaria = this.getCiudadByFilter(socioEconomico.inmobiliaria)[0]
    
    if (this.socioloaded != null)
      socioEconomico.id = this.socioloaded.id
      let user = JSON.parse(localStorage.getItem("key_login"));

    return this.socioEconomicoService.validate(socioEconomico,user.id).pipe(
      map(resp => {
        if (resp.data)
          localStorage.setItem(this.keySocioEconomico, JSON.stringify(socioEconomico));
        return resp
      })
    );
  }

}
