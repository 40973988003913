import { Component, OnInit } from '@angular/core';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-terminos',
  templateUrl: './terminos.component.html',
  styleUrls: ['./terminos.component.css']
})
export class TerminosComponent implements OnInit {

  pdfSrc:string="../../assets/pdf/Concurso Becas 2024.pdf";
  cargando:boolean = true;
  constructor() { }

  ngOnInit(): void {
  }
  callBackFn(pdf: PDFDocumentProxy) {
    // do anything with "pdf"
    this.cargando=false;
 }
 onPDFError(e:any){
   console.log(e);
 }
}
