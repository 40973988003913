import { ContactoComponent }          from "../contacto/contacto.component";
import { PostulanteComponent }        from "../postulante/postulante.component";
import { GratulationComponent }       from '../gratulation/gratulation.component';
import { ComentarioComponent }        from "../comentario/comentario.component";
import { TutorComponent }             from "../tutor/tutor.component";
import { GrupoFamiliarComponent }     from "../grupofamiliar/grupofamiliar.component";
import { SocioEconomicoComponent }    from "../socioeconomico/socioeconomico.component";
import { RealLoginComponent }        from "../RealLogin/Reallogin.component";
import { UploadComponent } from "../upload/upload.component";

export const ArrayRouting = [
  { name: 'Login',            component: RealLoginComponent },
  { name: 'contacto',         component: ContactoComponent },
  { name: 'postulante',       component: PostulanteComponent },
  { name: 'tutor',            component: TutorComponent },
  { name: 'grupofamiliar',    component: GrupoFamiliarComponent },
  { name: 'socioeconomico',   component: SocioEconomicoComponent },
  { name: 'uploads',          component: UploadComponent},
  { name: 'comentario',       component: ComentarioComponent },
  { name: 'gratulation',      component: GratulationComponent }
]
