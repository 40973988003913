import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Tutor } from "@interfaces/tutor";
import { Pais } from "@interfaces/pais";
import { Provincia } from "@interfaces/provincia";
import { Ciudad } from "@interfaces/ciudad";

import { LocalStorageService } from "../common/localStorage.service";
import { TutoreService } from "@api/tutor.service";
import { PAISES, ESTADOCIVIL } from "@api/DATA";

import {PaisService} from "@api/pais.service";
import { ProvinciaService} from "@api/provincia.service";
import { LocalidadService} from "@api/localidad.service";
import {EstadoCivilService} from "@api/estadoCivil.service";
import { Localidad } from "@interfaces/localidad";

@Component({
	selector: 'app-tutor',
	templateUrl: './tutor.component.html',
	styleUrls: ['./tutor.component.css'],
})
export class TutorComponent implements OnInit {

	data: Tutor;
	form: FormGroup;
	estadoscivil: any[];
	paises: Pais[];
	provincias: Provincia[];
	ciudades: Localidad[];
  tutorloaded: Tutor = null;

  valueComponent:string = "comp_tutor";
  keyComponent:string = "key_component";
  keytutor:string = "key_tutor";

	constructor(
		private tutoreService: TutoreService,
    private localStorageService: LocalStorageService,
		private fb: FormBuilder,
    private paisService:PaisService,
    private provinciaService:ProvinciaService,
    private localidadService:LocalidadService,
    private estadoCivilService : EstadoCivilService
	) { }

	ngOnInit(): void {
    this.localStorageService.setItem(this.keyComponent, this.valueComponent);
    this.paisService.getAll().subscribe(res => {
      this.paises = res;

      this.estadoCivilService.getAll().subscribe(res => {
        this.estadoscivil = res;

        this.form.controls.ingreso.setValue(0);
        
        let user = JSON.parse(localStorage.getItem("key_login"));
        this.tutoreService.getTutorByUser(user.id).subscribe(res => {
          if (res != null)
          {
            
            debugger;
              let t = res;
              this.provinciaService.getAllByPaisByCodigo(1,'AR-V').subscribe(res2 => {
              this.provincias = res2;
                
              this.localidadService.getAllByProvincia(t.ciudad.provincia.id).subscribe(res3 => {
                this.ciudades = res3;
              

                  this.form.controls.nombre.setValue(t.nombre);
                  this.form.controls.apellido.setValue(t.apellido);
                  this.form.controls.documento.setValue(t.documento);
                  this.form.controls.estadoCivil.setValue(t.estadoCivil.id);
                  this.form.controls.domicilio.setValue(t.domicilio);
                  this.form.controls.ciudad.setValue(t.ciudad.id);
                  this.form.controls.pais.setValue(t.ciudad.provincia.pais.id);
                  this.form.controls.provincia.setValue(t.ciudad.provincia.id);
                  this.form.controls.telefono.setValue(t.telefono);
                  this.form.controls.ocupacion.setValue(t.ocupacion);
                  this.form.controls.correo.setValue(t.correo);
                  this.form.controls.ingreso.setValue(t.ingreso);
                  this.tutorloaded = t;
              });

              });
          }
        
          });
        
      });
    });

	this.buildForm();

	}

	buildForm() {
		this.form = this.fb.group({
			nombre: [this.data?.nombre, [Validators.required, Validators.maxLength(50)]],
			apellido: [this.data?.apellido, [Validators.required, Validators.maxLength(50)]],
			documento: [this.data?.documento, [Validators.required, Validators.maxLength(8)]],
			estadoCivil: [this.data?.estadoCivil, Validators.required],
			domicilio: [this.data?.domicilio, [Validators.required, Validators.maxLength(80)]],
			ciudad: [this.data?.ciudad, Validators.required],
      pais: [null, Validators.required],
      provincia: [null, Validators.required],
			telefono: [this.data?.telefono, [Validators.required, Validators.maxLength(12)]],
			ocupacion: [this.data?.ocupacion, [Validators.required, Validators.maxLength(80)]],
			correo: [this.data?.correo, [Validators.required, Validators.email]],
			ingreso: [this.data?.ingreso, Validators.required],
		});
	}

	selectPais(value: number) {
    this.provinciaService.getAllByPaisByCodigo(value,'AR-V').subscribe(res => {
      this.provincias = res;
      this.form.controls.ciudad.setValue("0");
    });
	}

	selectProvincia(value: number) {
    this.localidadService.getAllByProvincia(value).subscribe(res => {
      this.ciudades = res;
      
    });
	}

	getFormValue(): Tutor{
		return {
			...this.form.value
		};
	}

  getCiudadByFilter(id):any{
    return this.ciudades.filter(x => x.id === (id*1));
  }

  getEstadoCivilByFilter(id):any{
    return this.estadoscivil.filter(x => x.id === (id*1));
  }

  validate():Observable<any>{
    debugger
    let tutor = this.getFormValue();

    tutor.estadoCivil = this.getEstadoCivilByFilter(tutor.estadoCivil)[0]
    tutor.ciudad = this.getCiudadByFilter(tutor.ciudad)[0]
    
    if (this.tutorloaded != null)
       tutor.id = this.tutorloaded.id

    let user = JSON.parse(localStorage.getItem("key_login"));
    return this.tutoreService.validate(tutor,user.id).pipe(
      map(resp => {
          if (resp)
          debugger;
            localStorage.setItem(this.keytutor, JSON.stringify(tutor));

          return resp
      }),
      catchError(err => of(err))
    );
  }
}
