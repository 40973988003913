import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DialogMensajeComponent } from "../dialogmensaje/dialogmensaje.component";
import {InscripcionService} from "@api/inscripcion.service";
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from "../common/localStorage.service";
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {

  columns: string[] = ['name', 'type', 'size', 'actions'];


  valueComponent:string = "comp_uploads";
  keyComponent:string = "key_component";
  keytutor:string = "key_upload";



  showLoad: boolean = false;
  disableToken:boolean = false;
  form: FormGroup;
  data:any;
  files:File[] = [];
  FilesLoaded:File[] = [];
  constructor(private fb: FormBuilder,
    private inscripcionService:InscripcionService,
    private localStorageService: LocalStorageService,
    private matDialog:MatDialog,) { }

  ngOnInit(): void {
    this.localStorageService.setItem(this.keyComponent, this.valueComponent);
    let user = JSON.parse(localStorage.getItem("key_login"));
    this.inscripcionService.downloadFilesByUserId(user.id).subscribe(resp => {
      debugger;
      this.FilesLoaded = resp;
      

    });

    this.buildForm();
  }
  buildForm() {
		this.form = this.fb.group({
			//token: [this.data?.token, [Validators.required, Validators.maxLength(50)]]
		});
	}

  /*getFormValue(): any {
		return {
			...this.form.value
		};
	}
*/
  selectFiles(files: FileList){
    if (files.length === 0)
    return;

    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
    }
  }

  click_remove(file:File){
    const index = this.files.indexOf(file, 0);
    this.files.splice(index,1);
  }
  uploadFile(){
    let user = JSON.parse(localStorage.getItem("key_login"));
    this.inscripcionService.uploadFile(user.id, this.files).subscribe(resp => {
      this.matDialog.open(DialogMensajeComponent,{data: resp.mensaje ? resp.mensaje : resp});
      if (resp.data){
        this.disableToken = false;
        this.showLoad= false;
        this.files = [];
      }
    });
  }



  validate():Observable<any>{
    
    debugger;
    let user = JSON.parse(localStorage.getItem("key_login"));
    return this.inscripcionService.validate(user.id).pipe(
      map(resp => {
          if (resp)
            return resp
      }),
      catchError(err => of(err))
    );
  }

}
