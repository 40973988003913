<h2 mat-dialog-title>TERMINOS Y CONDICIONES</h2>
<mat-dialog-content class="mat-typography">
  <pdf-viewer [src]="pdfSrc"
              [render-text]="true"
              style="display: block;"
              [original-size]="false"
              (after-load-complete)="callBackFn($event)"
              [autoresize]="true"
              (error)="onPDFError($event)"
  ></pdf-viewer>
  <p *ngIf="cargando">Cargando..</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Cerrar</button>
</mat-dialog-actions>
