import { Provincia } from '@interfaces/provincia';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError,map } from 'rxjs/operators';
import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class ProvinciaService extends RestApiService {
  getAllByPais(paisId:number): Observable<Provincia[]> {
    return this.http.get<Provincia[]>(this.apiURL + '/provincia/getAllByPais/'+paisId)
    .pipe(
      map(resp => {return resp}),
      retry(1),
      catchError(this.handleError)
    )
  }

  getAllByPaisByCodigo(paisId:number, codigo:string): Observable<Provincia[]> {
    return this.http.get<Provincia[]>(this.apiURL + '/provincia/getAllByPaisByCodigo/'+paisId+'/'+codigo)
    .pipe(
      map(resp => {return resp}),
      retry(1),
      catchError(this.handleError)
    )
  }

  
  getProvinciaByCiudad(ciudadid): Observable<Provincia> {
    return this.http.get<Provincia>(this.apiURL + '/provincia/getProvinciaByCiudad/'+ciudadid)
    .pipe(
      map(res => {return res;}),
      retry(0),
      catchError(this.handleError)
    )
  }

}
