import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Educacion } from '../interfaces/educacion';
import { Postulante } from '../interfaces/postulante';

import { InicialFormComponent } from "./forms/inicial/inicial.component";
import { PrimariaFormComponent } from "./forms/primaria/primaria.component";
import { SecundariaFormComponent } from "./forms/secundaria/secundaria.component";
import { SuperiorFormComponent } from "./forms/superior/superior.component";

import { LocalStorageService } from "../common/localStorage.service";
import { PostulanteService } from "@api/postulante.service";
import { EDUCACIONES } from "@api/DATA";

@Component({
	selector: 'app-postulante',
	templateUrl: './postulante.component.html',
	styleUrls: ['./postulante.component.css']
})
export class PostulanteComponent implements OnInit {
	educaciones: Educacion[] = [];
	selectedEducacion: Educacion;
	selectedEd: string;

	postulantes: Postulante[] = [];
	columns: string[] = ['id','dni', 'nombre', 'apellido','nivel', 'actionEdit','actions'];

  valueComponent:string = "comp_postulante";
  keyComponent:string = "key_component";
  keyPostulante:string = "key_Postulante";

	constructor(
		private postulanteService: PostulanteService,
    private localStorageService:LocalStorageService,
		public dialog: MatDialog
	) { }

	ngOnInit(): void {
    this.localStorageService.setItem(this.keyComponent, this.valueComponent);
    
	
	//this.postulantes = JSON.parse(this.localStorageService.getItem(this.keyPostulante)) || [];
	this.educaciones = EDUCACIONES;
	let user = JSON.parse(localStorage.getItem("key_login"));
	this.postulanteService.GetByUser(user.id).subscribe(res => {
		if (res != null)
		{
		  	this.postulantes = res.data;
		  }
  
	  });
	


	}

	addPostulante(postulante:Postulante = null,IsEdit = false) {
		var dialogRef = null;
		var data = {
			postulante: postulante,
			educacion: this.selectedEducacion
		};

		switch (this.selectedEducacion?.nombre) {
			case 'Inicial':
				dialogRef = this.dialog.open(InicialFormComponent, { data: data });
				break;
			case 'Primaria':
				dialogRef = this.dialog.open(PrimariaFormComponent, { data: data });
				break;
			case 'Secundaria':
				dialogRef = this.dialog.open(SecundariaFormComponent, { data: data });
				break;
			case 'Superior':
				dialogRef = this.dialog.open(SuperiorFormComponent, { data: data });
				break;
			default:
				dialogRef = this.dialog.open(InicialFormComponent, { data: data });
				break;
		}

		dialogRef.afterClosed().subscribe((result:Postulante) => {
		if (result) {
			if (!IsEdit)
			{
				let p = this.postulantes.find(x => x.documento ==result.documento);
				if (p == null)
				{
					const lowercaseKeys = obj =>
					Object.keys(obj).reduce((acc, key) => {
					  acc[key.toLowerCase()] = obj[key];
					  return acc;
					}, {});
					let postu =  lowercaseKeys(result) as Postulante
					this.postulantes.push(postu);
				}
				else
				window.alert("El numero de documento del postulante ya esta en la lista");
				this.postulantes = this.postulantes.slice();
			}
			else{
				const lowercaseKeys = obj =>
				Object.keys(obj).reduce((acc, key) => {
				  acc[key.toLowerCase()] = obj[key];
				  return acc;
				}, {});

				this.postulantes = this.postulantes.filter(item => item.documento !== postulante.documento);
				let postu =  lowercaseKeys(result) as Postulante
				postu.documento =  postulante.documento
				this.postulantes.push(postu);
				this.selectedEducacion = null;
			}
		}
		else
		{
			if (IsEdit) this.selectedEducacion = null;
		}
		});
	}

	

	

	selectEducacion(value: Educacion) {
		this.selectedEducacion = value;
	}

  click_add(postulante:Postulante):void{
    this.addPostulante(postulante);
  }

  click_remove(postulante:Postulante):void{
    if (postulante){
      this.postulantes = this.postulantes.filter(item => item.documento !== postulante.documento);
      this.localStorageService.setItem(this.keyPostulante, JSON.stringify(this.postulantes));

    }
  }


  click_edit(postulante:any):void{
    if (postulante){
      //let postulante = this.postulantes.filter(item => item.documento == postulante.documento)[0];
	  //this.postulantes = this.postulantes.filter(item => item.documento !== postulante.documento);
      //this.localStorageService.setItem(this.keyPostulante, JSON.stringify(this.postulantes));
	  this.selectedEducacion = this.educaciones.filter(item => item.nombre == postulante.niveleducativo)[0];
	  this.addPostulante(postulante,true);	
    }
  }

  validate():Observable<any> {
	debugger;
	let user = JSON.parse(localStorage.getItem("key_login"));
    return this.postulanteService.validate(this.postulantes,user.id).pipe(
      map(resp => {
        if (resp.data)
          this.localStorageService.setItem(this.keyPostulante, JSON.stringify(this.postulantes));
        return resp
      }),
      catchError(err => of(err))
    );
  }
}
