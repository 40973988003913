import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Familia } from "@interfaces/familia";
import { NivelEducativo } from '@interfaces/nivelEducativo';

import {  } from "@api/DATA";
import {NivelEducativoService} from "@api/nivelEducativo.service";

@Component({
	selector: 'form-familia',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.css'],
	providers: [
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: { hasBackdrop: true, width: "50vw" },
		},
	],
})
export class FamiliaFormComponent implements OnInit {

	form: FormGroup;
  nivelesEducativos: NivelEducativo[];
  vinculos:string[]= [];
  enableEnfermedad:boolean=false;
  enableDiscapacidad:boolean=false;
	constructor(
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: any,
    private nivelEducativoService:NivelEducativoService
	) { }

	ngOnInit(): void {

    this.nivelEducativoService.getAll().subscribe(resp => {
      this.nivelesEducativos= resp;

      this.vinculos = ['Padre','Madre','hija/o','Hermana/o','Conviviente', 'Otros'];
	  if (this.data != null) this.filldropdowns();
    });

		this.buildForm();
	}

	buildForm() {
		debugger
		this.form = this.fb.group({
			nombre: [this.data?.nombre, [Validators.required, Validators.maxLength(50)]],
			apellido: [this.data?.apellido, [Validators.required, Validators.maxLength(50)]],
			documento: [{value: this.data?.documento, disabled:this.data!=null}, [Validators.required, Validators.maxLength(8)]],
			edad: [this.data?.edad, Validators.required],
			vinculo: [this.data?.vinculo, Validators.maxLength(50)],
			nivelEducativo: [this.data?.niveleducativo, [Validators.required, Validators.maxLength(50)]],
			trabajo: [this.data?.trabajo, [Validators.required, Validators.maxLength(50)]],
			enfermedad: [this.data?.enfermedad, Validators.required],
			descripcionEnfermedad: [this.data?.descripcionenfermedad],
			discapacidad: [this.data?.discapacidad, Validators.required],
      		descripcionDiscapacidad: [this.data?.descripciondiscapacidad],
			ingreso: [this.data?.ingreso, Validators.required],
		});
	}

	rebuilddropdown(name,value)
	{
	  let paiselement = (document.getElementById(name) as HTMLElement)
  
	  let paisparent = paiselement.parentNode as HTMLElement;
  
	  let start = paisparent.className.lastIndexOf("ng-tns");
	  let end = paisparent.className.toString().length;
  
	  let classnameremove =   paisparent.className.substring(start,end);
  
	  let elementtoremove = "mat-form-field-label-wrapper" + " " + classnameremove
  
	  const elementstoremove = Array.from(document.getElementsByClassName(elementtoremove) as HTMLCollectionOf<HTMLElement>)
  
	  for (const el of elementstoremove) {
		//el.remove();
		debugger
		let elemntc =  el.childNodes[0] as HTMLElement;
		elemntc.className = elemntc.className.replace("mat-empty mat-form-field-empty","");
		elemntc.className = elemntc.className.replace("mat-form-field-label","");
		let elemntd =  elemntc.childNodes[1] as HTMLElement;
		elemntd.style.position = "absolute"
		elemntd.style.marginTop = "-12px"
		elemntd.style.color = "#aeabab"
		elemntd.style.fontSize = "11px"
	  }
  
	  

	  let elv = paiselement.querySelector('.mat-select-placeholder') as HTMLElement
	  if (elv != null)
	  {
	  	elv.className = elv.className.replace("mat-select-placeholder","");
	  	elv.innerHTML = value
	  }
	} 
	
  
	filldropdowns()
	{
	  this.rebuilddropdown("enfermedad",this.data.enfermedad? "Si":"No")
	  this.rebuilddropdown("discapacidad",this.data.discapacidad? "Si":"No")
	  this.rebuilddropdown("nivelEducativo",this.data.niveleducativo.nombre)

	  if  ((this.data.enfermedad != "")  || (this.data.enfermedad == null))
	  {
		this.enableEnfermedad = true;
	  }
  
	  if  ((this.data.discapacidad != "")  || (this.data.discapacidad == null))
	  {
		this.enableDiscapacidad = true;
	  }

	}
  

	getFormValue(): Familia {
    const { descripcionEnfermedad } = this.form.value;
    const { descripcionDiscapacidad } = this.form.value;

		return {
			...this.form.value,
			descripcionEnfermedad : descripcionEnfermedad === null ? "" : descripcionEnfermedad,
			descripcionDiscapacidad : descripcionDiscapacidad === null ? "" : descripcionDiscapacidad
		};
	}
  selectCondicionEnfermedad(value:boolean){
    this.enableEnfermedad = value;
	if (value)
		this.form.controls['descripcionEnfermedad'].setValue(null)

  }
  selectCondicionDiscapacidad(value:boolean){
    this.enableDiscapacidad = value;
	if (value)
		this.form.controls['descripcionDiscapacidad'].setValue(null)

  }
}
