import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { Postulante } from '@interfaces/postulante';

import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class PostulanteService extends RestApiService {
  validate(entity,userid:string): Observable<any> {
    return this.http.post<any>(this.apiURL + '/postulante/Validate/'+userid, JSON.stringify(entity), this.httpOptions)
    .pipe(
      map(res => {return res;}),
      catchError(this.handleError)
    )
  }

  GetByUser(userid:string): Observable<any> {
    return this.http.get<any>(this.apiURL + '/postulante/GetByUserId/'+userid)
    .pipe(
      map(resp => 
        {
          debugger
          return resp
        }),
      catchError(this.handleError)
    )
  }

}
