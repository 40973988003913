import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { Trabajo } from '@interfaces/trabajo';

import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class TrabajoService extends RestApiService {

  getAll(): Observable<Trabajo[]> {
    return this.http.get<Trabajo[]>(this.apiURL + '/trabajo')
    .pipe(
      map(res => {return res;}),
      retry(1),
      catchError(this.handleError)
    )
  }
}
