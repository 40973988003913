import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Contacto } from "@interfaces/contacto";

import { ContactoService } from "@api/contacto.service";
import{CompaniaTelefonicaService} from "@api/companiaTelefonica.service";
import { LocalStorageService } from "../common/localStorage.service";
import { CompaniaTelefonica } from '@interfaces/companiaTelefonica';

@Component({
	selector: 'app-contacto',
	templateUrl: './contacto.component.html',
	styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
	form: FormGroup;
	data: Contacto;
  companiaTelefonicas: CompaniaTelefonica[];
  contactcreated: Contacto;

  valueComponent:string = "comp_contacto";
  keyComponent:string = "key_component";
  keyContacto:string = "key_conctacto";

	constructor(
		private contactoService : ContactoService,
    private localStorageService:LocalStorageService,
		private fb: FormBuilder,
    private companiaTelefonicaService:CompaniaTelefonicaService
	) { }

	ngOnInit(): void {
    this.localStorageService.setItem(this.keyComponent, this.valueComponent);
    this.companiaTelefonicaService.getAll().subscribe(resp => {
      this.companiaTelefonicas= resp;
      debugger
      let c = JSON.parse(localStorage.getItem(this.keyContacto));
      if (c != null){
        this.form.controls.celular.setValue(c.celular);
        this.form.controls.codigoArea.setValue(c.codigoArea);
        this.form.controls.companiaTelefonica.setValue(c.companiaTelefonica.id);
        this.contactcreated = c;
      }
      else{
        let user = JSON.parse(localStorage.getItem("key_login"));
        this.contactoService.getContactoByUser(user.id).subscribe(data => {
          if (data != null)
          {
            //debugger;
              localStorage.setItem(this.keyContacto, JSON.stringify(data.contacto));
              this.data = data.contacto;
              this.form.controls.celular.setValue(data.contacto.celular);
              this.form.controls.codigoArea.setValue(data.contacto.codigoArea);
              this.form.controls.companiaTelefonica.setValue(data.contacto.companiaTelefonica.id); 
              this.contactcreated = data.contacto;
            }
    
        });
      }
    });

   


		this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group({
			celular: [this.data?.celular, [Validators.required, Validators.maxLength(12)]],
      codigoArea: [this.data?.codigoArea, [Validators.required, Validators.maxLength(5)]],
      companiaTelefonica: [this.data?.companiaTelefonica.id, [Validators.required]],
		},
    {
    });
	}

  getCompTelefonicaByFilter(id):any{
    return this.companiaTelefonicas.filter(x => x.id === (id*1));
  }

  getFormValue(): Contacto {

		return {
			...this.form.value
		};
	}
  validate():Observable<any> {
    //debugger;
    let contacto: Contacto  = this.getFormValue();    

    contacto.companiaTelefonica =  this.getCompTelefonicaByFilter(contacto.companiaTelefonica)[0]; 

    if (!contacto.companiaTelefonica)
      return of({data:false, mensaje:'Selecciones una compania de telefono'});

      if (contacto.celular == "")
      return of({data:false, mensaje:'Debe ingresar un numero de celular o telefono.'});

      if (contacto.codigoArea == "")
      return of({data:false, mensaje:'Debe ingresar codigo de area del numero de celular o telefono.'});
      

     let user = JSON.parse(localStorage.getItem("key_login"));

     if (this.contactcreated)
     {
        contacto.id = this.contactcreated.id;
     }

     return this.contactoService.validate(contacto,user.id).pipe(
       map(resp => {
         if (resp.data)
           localStorage.setItem(this.keyContacto, JSON.stringify(contacto));
         return resp
       }),
       catchError(err => of(err))
     );
  }
}

