import { map,catchError } from 'rxjs/operators';
import { Observable,of } from 'rxjs';


import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Estudiante } from "@interfaces/estudiante";

import { EstudianteOutsideFormComponent } from "./form/form.component";
import { LocalStorageService } from "../common/localStorage.service";
import { EstudianteService } from "@api/estudianteOutside.service";

@Component({
	selector: 'app-estudianteoutside',
	templateUrl: './estudianteoutside.component.html',
	styleUrls: ['./estudianteoutside.component.css']
})
export class EstudianteOutsideComponent implements OnInit {

	estudientes: Estudiante[] = [];
	columns: string[] = ['id', 'nombre', 'apellido', 'actions'];

  valueComponent:string = "comp_estudianteoutside";
  keyComponent:string = "key_component";
  keyEstudiante:string = "key_estudiante";

	constructor(
		private estudianteOutsideService: EstudianteService,
    private localStorageService: LocalStorageService,
		public dialog: MatDialog
	) { }

	ngOnInit(): void {
    this.localStorageService.setItem(this.keyComponent, this.valueComponent);
    this.estudientes = JSON.parse(this.localStorageService.getItem(this.keyEstudiante)) || [];
	}

	addEstudiante(estudiante:Estudiante=null) {
		var dialogRef = this.dialog.open(EstudianteOutsideFormComponent);

		dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let e = this.estudientes.find(x => x.documento == result.documento);
        if (e == null)
          this.estudientes.push(result);
          else
          window.alert("El numero de documento del postulante ya esta en la lista");
          this.estudientes = this.estudientes.slice();
			}
		});
	}
  click_add(estudiante:Estudiante):void{
    this.addEstudiante(estudiante);
  }

  click_remove(estudiante:Estudiante):void{
    if (estudiante){
      this.estudientes = this.estudientes.filter(item => item.documento !== estudiante.documento);
      this.localStorageService.setItem(this.keyEstudiante, JSON.stringify(this.estudientes));

    }
  }
  validate():Observable<any> {
    return this.estudianteOutsideService.validate(this.estudientes).pipe(
      map(resp => {
        if (resp.data)
          this.localStorageService.setItem(this.keyEstudiante, JSON.stringify(this.estudientes));
        return resp
      }),
      catchError(err => of(err))
    );
  }

}
