import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Familia } from '@interfaces/familia';

import { FamiliaFormComponent } from "./form/form.component";

import { LocalStorageService } from "../common/localStorage.service";
import { FamiliaService } from "@api/familia.service";
import { GRUPOfAMILIAR } from "@api/DATA";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
	selector: 'app-grupofamiliar',
	templateUrl: './grupofamiliar.component.html',
	styleUrls: ['./grupofamiliar.component.css']
})
export class GrupoFamiliarComponent implements OnInit {

	familiares: Familia[] = [];
	columns: string[] = ['id','dni', 'nombre', 'apellido', 'vinculo','actionsedit' ,'actions'];
	form: FormGroup;
  cantidad: number= 0;
  
  valueComponent:string = "comp_grupofamiliar";
  keyComponent:string = "key_component";
  keyFamiliar:string = "key_Familiar";

	constructor(
		private familiaService: FamiliaService,
    private localStorageService:LocalStorageService,
		public dialog: MatDialog,
    private fb: FormBuilder    
	) { }


	ngOnInit(): void {
    this.localStorageService.setItem(this.keyComponent, this.valueComponent);
    //this.familiares = JSON.parse(this.localStorageService.getItem(this.keyFamiliar)) || [];

  let user = JSON.parse(localStorage.getItem("key_login"));
  this.familiaService.getFamiliaresByUser(user.id).subscribe(res => {
    if (res != null)
    {
      debugger;
        this.familiares = res.data;
        this.cantidad = this.familiares.length
      }
  
    });
  
   


	}




	addFamilia(familia:Familia = null,IsEdit = false) {
		var dialogRef = this.dialog.open(FamiliaFormComponent,{ data: familia });

		dialogRef.afterClosed().subscribe(result => {
			if (result) {
        debugger
        if (!IsEdit)
        {
            let f = this.familiares.find(x => x.documento ==result.documento);
            if (f == null)
            {
              const lowercaseKeys = obj =>
              Object.keys(obj).reduce((acc, key) => {
              acc[key.toLowerCase()] = obj[key];
              return acc;
              }, {});

              let fam2 = lowercaseKeys(result) as Familia
              this.familiares.push(fam2)
              this.familiares = this.familiares.slice()
            }
            else
            {
              window.alert("El numero de documento del Familiar ya esta en la lista");
              this.familiares = this.familiares.slice()
            }
        }
        else
        {
         
          const lowercaseKeys = obj =>
          Object.keys(obj).reduce((acc, key) => {
            acc[key.toLowerCase()] = obj[key];
            return acc;
          }, {});

          this.familiares = this.familiares.filter(item => item.documento !== familia.documento);
          let fam =  lowercaseKeys(result) as Familia
          fam.documento =  familia.documento
          this.familiares.push(fam);

        }
			}
		});
	}
  click_add(familia:Familia):void{
    this.addFamilia(familia);
  }

  click_remove(familia:Familia):void{
    if (familia){
      this.familiares = this.familiares.filter(item => item.documento !== familia.documento);
      this.localStorageService.setItem(this.keyFamiliar, JSON.stringify(this.familiares));
    }
  }


  click_edit(familia:Familia):void{
    if (familia){
      this.addFamilia(familia,true);
    }
  }

  validate():Observable<any> {
    debugger;
    

    if (this.cantidad != this.familiares.length)
      return of({data:false, mensaje:'La cantidad de Familiaries debe ser igual a la ingresada.'});

    let user = JSON.parse(localStorage.getItem("key_login"));
    return this.familiaService.validate(this.familiares,user.id).pipe(
      map(resp => {
        if (resp.data)
          this.localStorageService.setItem(this.keyFamiliar, JSON.stringify(this.familiares));
        return resp
      }),
      catchError(err => of(err))
    );
  }

}
