<div class="demo-container p-p-3">
	<p class="sub-titulo">Grupo Familiar Conviviente</p>
	<p style="color: #3d73d5;font-style: oblique;font-weight: bold;">Declarar grupo familiar directo que no forme parte de la presente inscripción </p>
	<div class="example-form">
		<div class="p-d-flex">
			<div class="p-mr-2 p-as-center">
				<mat-form-field class="example-full-width">
					<mat-label>Cantidad Familiares <span> * </span></mat-label>
					<input type="number" matInput  [(ngModel)]="cantidad" />
				</mat-form-field>
				<button pButton type="button" class="p-button-raised p-button-rounded" icon="pi pi-plus"
					(click)="addFamilia()"></button>
			</div>
		</div>
		<br>
		<table *ngIf="familiares.length > 0" mat-table [dataSource]="familiares" class="mat-elevation-z8" style="width: 100%;">
			<tr mat-header-row *matHeaderRowDef="columns"></tr>
			<tr mat-row *matRowDef="let row; columns: columns;"></tr>

			<ng-container matColumnDef="id">
				<th mat-header-cell *matHeaderCellDef> No. </th>
				<td mat-cell *matCellDef="let element"> {{element.id}} </td>
			</ng-container>

			<ng-container matColumnDef="dni">
				<th mat-header-cell *matHeaderCellDef> DNI </th>
				<td mat-cell *matCellDef="let element"> {{element.documento}} </td>
			</ng-container>


			<ng-container matColumnDef="nombre">
				<th mat-header-cell *matHeaderCellDef> Nombre </th>
				<td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
			</ng-container>

			<ng-container matColumnDef="apellido">
				<th mat-header-cell *matHeaderCellDef> Apellido </th>
				<td mat-cell *matCellDef="let element"> {{element.apellido}} </td>
			</ng-container>

			<ng-container matColumnDef="vinculo">
				<th mat-header-cell *matHeaderCellDef> Vinculo </th>
				<td mat-cell *matCellDef="let element"> {{element.vinculo}} </td>
			</ng-container>

			<ng-container matColumnDef="actionsedit">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let element">
					<button mat-button color="primary"(click)="click_edit(element)" >
						Editar
					</button>
				</td>
			</ng-container>


			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let element">
					<button mat-button color="warn"(click)="click_remove(element)" >
						Eliminar
					</button>
				</td>
			</ng-container>

		</table>
	</div>
</div>
