<div>
  <button mat-flat-button color="warn" style="width: 100%;line-height: 12px;"><mat-icon aria-hidden="false">warning</mat-icon> 
    <p>Ingrese el número de celular de contacto en el cual, en caso de aprobarse el beneficio, recibirá los datos de cobro"</p>
      <p> Al clickear en siguiente, un cuadro advertencia con la leyenda "Es responsabilidad de cada beneficiario  mantener </p>
       <p> los datos de contacto ACTUALIZADOS para la correcta recepción del beneficio (cambio de compañía, </p>
      <p>cambio de número de teléfono y/o pérdida/robo del mismo)"</p>
    </button>
    <br>
  <form [formGroup]="form" class="form-container" autocomplete="off">
    <mat-form-field class="example-full-width">
      <mat-label>Compania telefonica</mat-label>
      <select matNativeControl  formControlName="companiaTelefonica" style="text-transform: uppercase;">
        <option *ngFor="let companiaTelefonica of companiaTelefonicas" [value]="companiaTelefonica.id">
          {{ companiaTelefonica.nombre }}
        </option>
      </select>
    </mat-form-field>
    <br>
    <mat-form-field class="example-full-width">
      <mat-label>Codigo Area <span> * </span></mat-label>
      <input type="text" #codigoArea maxlength="5" matInput formControlName="codigoArea" />
      <mat-hint align="end">{{codigoArea.value?.length || 0}}/5</mat-hint>
    </mat-form-field>
    <br>
    <mat-form-field class="example-full-width">
      <mat-label>Celular <span> * </span></mat-label>
      <input type="text" #celular maxlength="12" matInput formControlName="celular" />
      <mat-hint align="end">{{celular.value?.length || 0}}/12</mat-hint>
    </mat-form-field>
    
  </form>
</div>
