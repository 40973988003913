<div class="example-container">
<mat-dialog-content>
  <form [formGroup]="options">
    <div>
      <mat-radio-group [formControl]="floatLabelControl">
        <mat-radio-button value="CONTACTO">CONTACTO(MAIL)</mat-radio-button>
        <mat-radio-button value="TUTOR">TUTOR(DNI)</mat-radio-button>
        <mat-radio-button value="INSCRIPCION">INSCRIPCION(USERID)</mat-radio-button>
        <mat-radio-button value="POSTULANTE">POSTULANTE(DNI)</mat-radio-button>
      </mat-radio-group>
    </div>
    <div>
      <mat-form-field class="example-full-width">
        <mat-label>Input</mat-label>
        <input type="text" #inputValue matInput formControlName="inputValue"/>
      </mat-form-field>
    </div>
    <div class="example-button-row">
      <button mat-raised-button color="primary" [disabled]="!this.options.valid" (click)="onClick()">Consulta</button>
    </div>
    <div *ngIf="isResult">
      <div *ngFor="let r of reportes">
        <mat-card>
          <mat-card-header>

            <mat-card-title>Inscripcion: {{r.fecha}} - {{r.inscripcion.id}}
              <button mat-icon-button title="Descargar documento" (click)="onClick_download(r.inscripcion)" [disabled]="!(r.inscripcion.uploadedFiles)">
                <mat-icon style="margin-top:-10px;" *ngIf="r.inscripcion.uploadedFiles">cloud_download</mat-icon>
                <mat-icon style="margin-top:-10px;" *ngIf="!r.inscripcion.uploadedFiles">cloud_off</mat-icon>
              </button>
            </mat-card-title>

          </mat-card-header>
          <mat-card-content>
            <div class="alert">
              <strong class="contacto">Contacto</strong>
            </div>
            <table>
              <tr>
                <th>Codigo Area</th>
                <th>Celular</th>
                <th></th>
              </tr>
              <tr>
                <td>{{r.contactos.codigoArea}}</td>
                <td>{{r.contactos.celular}}</td>
                <td><button class="SendMail" mat-raised-button color="primary" *ngIf="false" (click)="click_SendMailAdjunto(r.inscripcion)">Mail Adjuntos</button></td>
              </tr>
            </table>

            <div class="alert">
              <strong>Tutor</strong>
            </div>
            <table>
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Documento</th>
                <th>Correo</th>
                <th>Ingreso</th>
              </tr>
              <tr>
                <td>{{r.tutor.nombre}}</td>
                <td>{{r.tutor.apellido}}</td>
                <td>{{r.tutor.documento}}</td>
                <td>{{r.tutor.correo}}</td>
                <td>{{r.tutor.ingreso}}</td>
              </tr>
            </table>

            <div class="alert">
              <strong>Postulantes</strong>
            </div>
            <table>
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Documento</th>
                <th>Cuil</th>
              </tr>
              <tr *ngFor="let p of r.postulantes">
                <td>{{p.nombre}}</td>
                <td>{{p.apellido}}</td>
                <td>{{p.documento}}</td>
                <td>{{p.cuil}}</td>
              </tr>
            </table>

            <div class="alert">
              <strong>socioeconomico</strong>
            </div>
            <table>
              <tr>
                <th>IngresoExtra</th>
                <th>IngresoGFC</th>
                <th>IngresoGFCO</th>
                <th>IngresoTotal</th>
                <th>IngresoTutor</th>
                <th>MontoAlquiler</th>
                <th>OtrosBienes</th>
                <th>TipoServicio</th>
                <th>Vehiculo</th>
              </tr>
              <tr>
                <td>{{r.socioeconomico?.ingresoExtra}}</td>
                <td>{{r.socioeconomico?.ingresoGFC}}</td>
                <td>{{r.socioeconomico?.ingresoGFCO}}</td>
                <td>{{r.socioeconomico?.ingresoTotal}}</td>
                <td>{{r.socioeconomico?.ingresoTutor}}</td>
                <td>{{r.socioeconomico?.inmobiliaria}}</td>
                <td>{{r.socioeconomico?.montoAlquiler}}</td>
                <td>{{r.socioeconomico?.otrosBienes}}</td>
                <td>{{r.socioeconomico?.tipoServicio}}</td>
                <td>{{r.socioeconomico?.vehiculo}}</td>
              </tr>
            </table>

            <div class="alert">
              <strong>Familiares</strong>
            </div>
            <table>
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>documento</th>
                <th>edad</th>
                <th>Vinculo</th>
                <th>descripcionDiscapacidad</th>
                <th>descripcionEnfermedad</th>
                <th>discapacidad</th>
                <th>enfermedad</th>
                <th>nivelEducativo</th>
                <th>ingreso</th>
              </tr>
              <tr *ngFor="let f of r.familiares">
                <td>{{f.nombre}}</td>
                <td>{{f.apellido}}</td>
                <td>{{f.documento}}</td>
                <td>{{f.edad}}</td>
                <td>{{f.vinculo}}</td>
                <td>{{f.descripcionDiscapacidad}}</td>
                <td>{{f.descripcionEnfermedad}}</td>
                <td>{{f.discapacidad}}</td>
                <td>{{f.enfermedad}}</td>
                <td>{{f.nivelEducativo}}</td>
                <td>{{f.ingreso}}</td>
              </tr>
            </table>

            <div class="alert">
              <strong>Estudiantes</strong>
            </div>
            <table>
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Documento</th>
              </tr>
              <tr *ngFor="let e of r.estudiantes">
                <td>{{e?.nombre}}</td>
                <td>{{e?.apellido}}</td>
                <td>{{e?.documento}}</td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</mat-dialog-content>
</div>
