import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError,map } from 'rxjs/operators';
import { Contacto } from '@interfaces/contacto';
import { RestApiService } from "./restApi.service";
import { Inscripcion } from '../interfaces/Inscripcion';

@Injectable({
  providedIn: 'root'
})

export class ContactoService extends RestApiService {
  validate(entity,userid:string): Observable<any> {
    return this.http.post<any>(this.apiURL + '/contacto/Validate/' +userid, JSON.stringify(entity), this.httpOptions)
    .pipe(
      map(res => {return res;}),
      retry(1),
      catchError(this.handleError)
    )
  }

  getContactoByUser(userid:string): Observable<any> {
    return this.http.get<any>(this.apiURL + '/contacto/GetByUserId/'+userid)
    .pipe(
      map(resp => 
        {
          return resp.data
        }),
      catchError(this.handleError)
    )
  }

}
