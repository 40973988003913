import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from "../common/localStorage.service";

@Component({
  selector: 'app-gratulation',
  templateUrl: './gratulation.component.html',
  styleUrls: ['./gratulation.component.css']
})
export class GratulationComponent implements OnInit {

  valueComponent:string = "comp_gratulation";
  keyComponent:string = "key_component";
  keyGratulation:string="key_gratulation";
  msj:string = "";
  constructor(private localStorageService:LocalStorageService) { }

  ngOnInit(): void {
    this.localStorageService.setItem(this.keyComponent, this.valueComponent);
    this.msj = JSON.parse(this.localStorageService.getItem(this.keyGratulation));
    this.localStorageService.clear();
  }

  validate():boolean{
    return false;
  }

}
