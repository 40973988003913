import { Postulante } from "@interfaces/postulante";
import { Educacion } from "@interfaces/educacion";
import { Pais } from "@interfaces/pais";
import { Escuela } from "@interfaces/escuela";
import { Inmobiliaria } from '@interfaces/inmobiliaria';
import { Condicion } from "@interfaces/condicion";
import { ServicioHogar } from "@interfaces/servicio";
import { Familia } from "@interfaces/familia";

const EDUCACIONES: Educacion[] = [
	{
		id: 1,
		nombre: 'Inicial',
		servicios: [
			{
				id: 1,
				nombre: 'Pública',
				educacion_id: 1,
				escuelas:[]
			},
			{
				id: 2,
				nombre: 'Privada',
				educacion_id: 1,
				escuelas:[]
			}
		]
	},
	{
		id: 2,
		nombre: 'Primaria',
		servicios: [
			{
				id: 1,
				nombre: 'Pública',
				educacion_id: 1,
				escuelas:[]
			},
			{
				id: 2,
				nombre: 'Privada',
				educacion_id: 1,
				escuelas:[]
			}
		]
	},
	{
		id: 3,
		nombre: 'Secundaria',
		servicios:[
			{
				id: 1,
				nombre: 'Pública',
				educacion_id: 1,
				escuelas:[]
			},
			{
				id: 2,
				nombre: 'Privada',
				educacion_id: 1,
				escuelas:[]
			}
		]
	},
	{
		id: 4,
		nombre: 'Superior',
		servicios: [
			{
				id: 1,
				nombre: 'Pública',
				educacion_id: 1,
				escuelas:[]
			},
			{
				id: 2,
				nombre: 'Privada',
				educacion_id: 1,
				escuelas:[]
			}
		]
	}
];

const GRUPOfAMILIAR: Familia[] = [
	{
		id: 1,
		nombre: 'nombre_1',
		apellido: 'apellido_2',
		documento: 'dni_1',
		edad: 25,
		vinculo: 'Hermano',
		nivelEducativo: null,
		trabajo: 'Çamping',
		enfermedad: false,
		descripcionEnfermedad: '',
		discapacidad: false,
    descripcionDiscapacidad: '',
		ingreso: 100,
	}
];

const PAISES: Pais[] = [
	{
		id: 1,
		nombre: 'Argentina',
		provincias: [
			{
				id: 1,
				nombre: 'New South Wales',
				pais_id: 1,
				ciudades: [
					{
						id: 1,
						nombre: 'Sydney',
						provincia_id: 1
					},
					{
						id: 2,
						nombre: 'Newcastle',
						provincia_id: 1
					},
					{
						id: 2,
						nombre: 'Wollongong',
						provincia_id: 1,
					}
				]
			},
			{
				id: 2,
				nombre: 'Queensland',
				pais_id: 1,
				ciudades: [
					{
						id: 1,
						nombre: 'Brisbane',
						provincia_id: 2
					},
					{
						id: 2,
						nombre: 'Townsville',
						provincia_id: 2
					}
				]
			},

		]
	},
];

const ESTADOCIVIL = [
	{
		id: 1,
		nombre: "Casado"
	},
	{
		id: 2,
		nombre: "soltero"
	}
];

const INMOBILIARIA: Inmobiliaria[] = [
	{id:1,nombre:'Alquiler'},
	{id:2,nombre:'Propio'},
	{id:3,nombre:'Prestada'},
];

const SERVICIOS: ServicioHogar[] = [
	{
		id: 1,
		nombre: "Agua"
	},
	{
		id: 2,
		nombre: "Cloacal"
	},
	{
		id: 3,
		nombre: "Gas"
	}
];

const TIPO_VIVIENDAS = [
	{
		id: 1,
		nombre: "Alquiler"
	},
	{
		id: 2,
		nombre: "Pensión"
	}
];

export {
	EDUCACIONES,
	PAISES,
	ESTADOCIVIL,
	INMOBILIARIA,
	SERVICIOS,
	TIPO_VIVIENDAS,
	GRUPOfAMILIAR
};
