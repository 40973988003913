import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { Estudiante } from '@interfaces/estudiante';

import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class EstudianteService extends RestApiService {
  validate(entity): Observable<any> {
    return this.http.post<any>(this.apiURL + '/estudiante/Validate', JSON.stringify(entity), this.httpOptions)
    .pipe(
      map(res => {return res;}),
      retry(1),
      catchError(this.handleError)
    )
  }
}
