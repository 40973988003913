import { Type, NgModuleFactory } from "@angular/core";
import { Route, Data, ResolveData } from "@angular/router";
import { Observable }              from "rxjs";

export enum MenuType {
    BRAND,
    LEFT,
    RIGHT
}

export interface RouteInfo {
    path: string;
    pathMatch?: "full" | "prefix";
    component?: Type<any>;
    redirectTo?: string;
    outlet?: string;
    canActivate?: any[];
    canDeactivate?: any[];
    data?: Data;
    resolve?: ResolveData;
    children?: Route[];
    loadChildren?: string,
    title: string;
    menuType: MenuType;
}