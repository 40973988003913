import { Stages } from './../common/Stages';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError,map } from 'rxjs/operators';
import { Contacto } from '@interfaces/contacto';
import { RestApiService } from "./restApi.service";
import { saveAs } from  "file-saver";
@Injectable({
  providedIn: 'root'
})

export class InscripcionService extends RestApiService {
  create(entity,userid:string): Observable<any> {
    return this.http.post<any>(this.apiURL + '/inscripcion/Create/'+userid, JSON.stringify(entity), this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  validate(userid:string): Observable<any> {
    return this.http.post<any>(this.apiURL + '/inscripcion/Validate/'+userid, this.httpOptions)
    .pipe(
      map(res => {return res;}),
      catchError(this.handleError)
    )
  }

  stages(): Observable<any> {
    return this.http.get<any>(this.apiURL + '/inscripcion/stage', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  validToken(token:string): Observable<any> {
    return this.http.post<any>(this.apiURL + '/inscripcion/validToken', JSON.stringify(token), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  uploadFile(userid:string, files: File[]):Observable<any>{
    const formData = new FormData();
    files.forEach((f) => formData.append('files', f));

    return this.http.post<any>(this.apiURL + '/inscripcion/uploadFile/'+userid ,formData, {reportProgress:false})
    .pipe(
      catchError(this.handleError)
    )
  }

  sendAdjuntoByUserId(token: string): Observable<any> {
    return this.http.get<any>(this.apiURL + '/inscripcion/SendAdjuntoByUserId/'+token, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  sendAdjuntoAll(): Observable<any> {
    return this.http.get<any>(this.apiURL + '/inscripcion/SendAdjuntoAll', this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  changeMail(token:string, mail:string): Observable<any> {
    return this.http.post<any>(this.apiURL + '/inscripcion/ChangeMail', JSON.stringify({token,mail}), this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }

  downloadFilesByUserId(userid:string): Observable<any> {
    return this.http.get<any>(this.apiURL + '/inscripcion/DownloadFile/'+userid, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  GetComments(userid:string): Observable<any> {
    return this.http.get<any>(this.apiURL + '/inscripcion/GetComments/'+userid, this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  

  downloadZipByToken_(userid:string): Observable<any> {
    return this.http.get<any>(this.apiURL + '/inscripcion/downloadZip/'+userid, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.handleError)
    )
  }
  downloadZipByToken(userid:string) {
    let fileName = userid+'.zip';
    let checkFileType = fileName.split('.').pop();
    var fileType;
    if (checkFileType == "txt") {
        fileType = "text/plain";
    }
    if (checkFileType == "pdf") {
        fileType = "application/pdf";
    }
    if (checkFileType == "doc") {
        fileType = "application/vnd.ms-word";
    }
    if (checkFileType == "zip") {
      fileType = "application/zip";
  }
    if (checkFileType == "docx") {
        fileType = "application/vnd.ms-word";
    }
    if (checkFileType == "xls") {
        fileType = "application/vnd.ms-excel";
    }
    if (checkFileType == "png") {
        fileType = "image/png";
    }
    if (checkFileType == "jpg") {
        fileType = "image/jpeg";
    }
    if (checkFileType == "jpeg") {
        fileType = "image/jpeg";
    }
    if (checkFileType == "gif") {
        fileType = "image/gif";
    }
    if (checkFileType == "csv") {
        fileType = "text/csv";
    }
    this.DownloadFile(userid, fileType)
        .subscribe(
            success => {
                saveAs(success, fileName);
            },
            err => {
                alert("Server error while downloading file.");
            }
        );
      }

      DownloadFile(filePath: string, fileType: string): Observable<any> {

        let fileExtension = fileType;
        let input = filePath;

        return this.http.get(this.apiURL + '/inscripcion/downloadZip/'+filePath, {
            responseType: 'blob',
            observe: 'response'
        })
            .pipe(
                map((res: any) => {
                    return new Blob([res.body], { type: fileExtension });
                })
            );
    }
}
