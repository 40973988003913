import { LoginDto } from '../interfaces/loginDto';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from '../common/localStorage.service';
import { empty, Observable, Subject, throwError } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { LoginService } from '../api/login.service';
import { RegisterDTO } from '../interfaces/RegisterDTO';
import { catchError, map } from 'rxjs/operators';
//import { LoginService } from "@api/login.service";

@Component({
  selector: 'app-reallogin',
  templateUrl: './Reallogin.component.html',
  styleUrls: ['./Reallogin.component.css']
})
export class RealLoginComponent implements OnInit {
  form: FormGroup;
  valueComponent:string = "comp_login";
  keyComponent:string = "key_component";
  keyLogin:string = "key_login";

  username: string = ""
  passwrod: string = ""
  
  login: LoginDto;
  loginInvalid: boolean = false;
  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private loginService: LoginService,
    private localStorageService:LocalStorageService)
    {
      this.form = this.fb.group({
        username: ['', Validators.email],
        password: ['', Validators.required]
      });
    }

  ngOnInit(): void {
    debugger
    this.localStorageService.setItem(this.keyComponent, this.valueComponent);
    
    this.buildForm();
  }
  buildForm() {
    //throw new Error('Method not implemented.');
  }

  validate():Observable<any>{
    this.login= {userName: this.username, password:this.passwrod}
    return this.loginService.validate(this.login).pipe(
      map(resp => {
        if (resp.data)
          localStorage.setItem(this.keyLogin, JSON.stringify(resp.data));
        return resp
      }),
      catchError(async (err) => console.log(err))
    );
  }


  getFormValue(): LoginDto {

		return {
			...this.form.value
		};
	}

  openDialogRegister(): void {
    debugger
    const dialogRef = this.dialog.open(DialogRegister, {
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      //result.close();

    });
  }

  openDialogResetPassword(): void {
    debugger
    const dialogRef = this.dialog.open(DialogResetpassword, {
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      //result.close();

    });
  }



}





@Component({
  selector: 'app-dialogregister',
  templateUrl: 'Registeruser.component.html',
})
export class DialogRegister implements OnInit {

  msgsucces: string = "";
  msgerror: string = "";
  register: RegisterDTO;
  private _error = new Subject<string>();
  private _success = new Subject<string>();
  showSpinner:boolean = false;
  @ViewChild('alerterror', {static: false}) alerterror: NgbAlert;
  @ViewChild('alertsucces', {static: false}) alertsucces: NgbAlert;


  constructor(
    public dialogRef: MatDialogRef<DialogRegister>,
    private loginService: LoginService

    ){ }
    


  onNoClick(): void {
    this.dialogRef.close();
  }


  ngOnInit() {
    this._error.subscribe(message => this.msgerror =  message);
    this._success.subscribe(message => this.msgsucces =  message);
    
    this.dialogRef.updateSize('45%', '48%');

    this.register = {
      email:"",name:"",password:"",passwordrepeated:"",rol:""
    };
  }

  
  validate():boolean
  {
    

    if(this.register.password == "" || this.register.passwordrepeated == "")
    {
      this._error.next(`Debe ingresar la contraseña`);
      return false;
    }

    if(this.register.password != this.register.passwordrepeated)
    {
      this._error.next(`Las contraseñas no coinciden.`);
      return false;
    }

    const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if (!regex.test(this.register.email))  
    {
      this._error.next(`Debe ingresar un correo electronico valido.`);
      return false;
    }
   
      return true;

  }

  Registrar(): void {

    this._error.next("");
    this._success.next("");
    this.showSpinner = true;
    if (!this.validate())
    {
      this.showSpinner = false;
      return;
    }

    this.register.name = this.register.email;
    this.register.rol = "User"

    try{
      this.loginService.Register(this.register).subscribe(x => {
        this.showSpinner = false;
        if (x.success)
        {
            this._success.next(`Se genero el usuario correctamente, por favor revise su email bandeja de entrada y spam para activar su cuenta.`);
        }
        else
        {
          this._error.next(x.errors[0]);
        }
        
      });
     } 
    catch (err) 
    {
      this.showSpinner = false;
      this.handleError(err)
    }
}

    //this._error.next(`Debe completar el puntaje con 4 decimales. por ejemplo 2.0000`);
    //this._success.next(`Debe completar el puntaje con 4 decimales. por ejemplo 2.0000`);
    
    //this.dialogRef.close();


  Close(): void {
    this.dialogRef.close();
  }
  

  

  handleError(error) {
    console.log(error)
    this._error.next(`Se genero el error: ${error}, por favor vuelva a intentar.`);
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    return throwError(error);
  }

}

  @Component({
    selector: 'app-dialogresetpassword',
    templateUrl: 'Resetpassword.component.html',
  })
  export class DialogResetpassword implements OnInit {
  
    msgsucces: string = "";
    msgerror: string = "";
    login: LoginDto;
    private _error = new Subject<string>();
    private _success = new Subject<string>();
    showSpinner:boolean = false;
    @ViewChild('alerterror', {static: false}) alerterror: NgbAlert;
    @ViewChild('alertsucces', {static: false}) alertsucces: NgbAlert;
  
  
    constructor(
      public dialogRef: MatDialogRef<DialogRegister>,
      private loginService: LoginService
  
      ){ }
      
  
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  
    ngOnInit() {
      this._error.subscribe(message => this.msgerror =  message);
      this._success.subscribe(message => this.msgsucces =  message);
      
      this.dialogRef.updateSize('30%', '36%');
  
      this.login = {
        userName:"",password:""
      };
    }
  
    
    validate():boolean
    {
      
  
      if(this.login.userName == "")
      {
        this._error.next(`Debe ingresar el correo electronico.`);
        return false;
      }
  
  
      const regex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
      if (!regex.test(this.login.userName))  
      {
        this._error.next(`Debe ingresar un correo electronico valido.`);
        return false;
      }
     
        return true;
  
    }
  
    Resetpassword(): void {
  
      this._error.next("");
      this._success.next("");

      this.showSpinner = true;
      if (!this.validate())
      {
        this.showSpinner = false;
        return;
      }

  
      try{
        this.loginService.ResetPassword(this.login).subscribe(x => {
          debugger;
          this.showSpinner = false;
          if (x.success)
          {
              this._success.next(`Se regenero la contreaseña, por favor revise su email en bandeja de entrada y spam para encontrar su nueva contraseña.`);
          }
          else
          {
            this._error.next(x.response);
          }
          
        });
       } 
      catch (err) 
      {
        this.showSpinner = false;
        this.handleError(err)
      }
  }
  
      //this._error.next(`Debe completar el puntaje con 4 decimales. por ejemplo 2.0000`);
      //this._success.next(`Debe completar el puntaje con 4 decimales. por ejemplo 2.0000`);
      
      //this.dialogRef.close();
  
  
    Close(): void {
      this.dialogRef.close();
    }
    
  
    
  
    handleError(error) {
      console.log(error)
      this._error.next(`Se genero el error: ${error}, por favor vuelva a intentar.`);
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      return throwError(error);
    }
  
  
  }

