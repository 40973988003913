import { Localidad } from './../interfaces/localidad';
import { Provincia } from '@interfaces/provincia';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError,map } from 'rxjs/operators';
import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class LocalidadService extends RestApiService {

  getAllByProvincia(provinciaId:number): Observable<Localidad[]> {
    return this.http.get<Localidad[]>(this.apiURL + '/localidad/getAllByProvincia/'+provinciaId)
    .pipe(
      map(resp => {return resp}),
      retry(1),
      catchError(this.handleError)
    )
  }
}
