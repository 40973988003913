<div class="demo-container p-p-3">
	<p class="sub-titulo">Socioeconomico</p>
	<p>Datos Socioeconomico Habitacional Patrimonio grupo Familiar Conviviente y Solicitante</p>
	<form [formGroup]="form" class="form-container" autocomplete="off">
		<mat-form-field class="example-full-width">
			<mat-label>Propiedad <span> * </span></mat-label>

			<select matNativeControl  formControlName="inmobiliaria"  style="text-transform: uppercase;">
				<option value="0">Seleccionar</option>
			<option *ngFor="let inmobiliaria of inmobiliarias" [value]="inmobiliaria.id">
			  {{ inmobiliaria.nombre }}
			</option>
		  </select>
		</mat-form-field>

		<mat-form-field class="example-full-width" *ngIf="inmobiliaria?.nombre.toUpperCase() == 'ALQUILER'">
			<mat-label>Monto Alquiler <span> * </span></mat-label>
			<input type="number" #montoAlquiler matInput formControlName="montoAlquiler"/>
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Servicios <span> * </span></mat-label>
			<mat-select  formControlName="tipoServicio" multiple>
				<mat-option [value]="servicio.nombre" *ngFor="let servicio of servicios">
					{{ servicio.nombre }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Otros Bienes </mat-label>
			<input type="text" #otrosBienes maxlength="50" matInput formControlName="otrosBienes" />
			<mat-hint align="end">{{otrosBienes.value?.length || 0}}/50</mat-hint>
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Vehiculo (Indicar Marca y Modelo)</mat-label>
			<input type="text" #vehiculo maxlength="50" matInput formControlName="vehiculo">
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Ingreso Mensual (Grupo Familiar Conviviente) <span> * </span></mat-label>
			<input type="number" #ingresoGFC matInput formControlName="ingresoGFC" readonly/>
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Ingreso Tutor <span> * </span></mat-label>
			<input type="number" #ingresoTutor matInput formControlName="ingresoTutor"/>
		</mat-form-field>

    <mat-form-field class="example-full-width">
			<mat-label>Ingreso Mensual (Grupo Familiar Conviviente otro) <span> * </span></mat-label>
			<input type="number" #ingresoGFCO matInput formControlName="ingresoGFCO"/>
		</mat-form-field>

		<mat-form-field class="example-full-width">
			<mat-label>Ingresos Extras (Cuota Alimentaria-Plan Social-Pension)<span> * </span></mat-label>
			<input type="number" #ingresoExtra matInput formControlName="ingresoExtra" />
		</mat-form-field>
		<br>
	</form>
</div>
