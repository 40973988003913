import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Familia } from '@interfaces/familia';
import { Tutor } from '@interfaces/tutor';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import {Contacto} from "@interfaces/contacto";
import {Postulante} from "@interfaces/postulante";

import { LocalStorageService } from "../common/localStorage.service";
import { ComentarioService } from "@api/comentario.service";
import {InscripcionService} from "@api/inscripcion.service";
import { SocioEconomico } from '@interfaces/socioEconomico';
import { Estudiante } from '@interfaces/estudiante';

@Component({
	selector: 'app-comentario',
	templateUrl: './comentario.component.html',
	styleUrls: ['./comentario.component.css']
})
export class ComentarioComponent implements OnInit {
	form: FormGroup;

  valueComponent:string = "comp_comentario";
  keyComponent:string = "key_component";

  keyContacto:string = "key_conctacto";
  keyPostulante:string = "key_Postulante";
  keytutor:string = "key_tutor";
  keyFamiliar:string = "key_Familiar";
  keySocioEconomico:string = "key_socioEconomico";
  keyEstudiante:string = "key_estudiante";
  keyGratulation:string="key_gratulation";
	constructor(
		private comentarioService: ComentarioService,
    private localStorageService:LocalStorageService,
		private fb: FormBuilder,
    private inscripcionService:InscripcionService
	) { }

	ngOnInit(): void {
    this.localStorageService.setItem(this.keyComponent, this.valueComponent);

    let user = JSON.parse(localStorage.getItem("key_login"));
    debugger;
    this.inscripcionService.GetComments(user.id).subscribe(res => {
      if (res != null)
      {
        debugger;
          this.form.controls.comentario.setValue(res.data.comentario);
        }
    
      });

		this.buildForm();
	}

	buildForm() {
		this.form = this.fb.group({
			comentario: ['', Validators.maxLength(250)],
		});
	}

	getFormValue() {
		return {
			...this.form.value
		};
	}

  validate():Observable<any>{
    // recolecto toda la info para validar y persistir
    //let dataValidate = {contacto:null,postulantes:[],tutor:null,familiares:[],socioeconomico:null,estudiantes:[],comentario:''}};
    let dataValidate= {contacto:null,postulantes:[],tutor:null,familiares:[],socioeconomico:null,estudiantes:[],comentario:''};

    dataValidate.contacto = <Contacto>JSON.parse(this.localStorageService.getItem(this.keyContacto));
    dataValidate.postulantes = <Postulante[]>JSON.parse(this.localStorageService.getItem(this.keyPostulante));
    dataValidate.tutor = <Tutor>JSON.parse(this.localStorageService.getItem(this.keytutor));
    dataValidate.familiares = <Familia[]>JSON.parse(this.localStorageService.getItem(this.keyFamiliar));
    dataValidate.socioeconomico = <SocioEconomico>JSON.parse(this.localStorageService.getItem(this.keySocioEconomico));
    dataValidate.estudiantes = <Estudiante[]>JSON.parse(this.localStorageService.getItem(this.keyEstudiante));
    dataValidate.comentario = this.getFormValue().comentario;

    let user = JSON.parse(localStorage.getItem("key_login"));

    return this.inscripcionService.create(dataValidate,user.id).pipe(
      map(resp => {
        if (resp.data){
          this.localStorageService.setItem(this.keyGratulation, JSON.stringify(resp.mensaje));
        }
        return resp
      }),
      catchError(err => of(err))
    );
  }

}
