
<div class="demo-container p-p-3">
  <button mat-flat-button color="warn" style="width: 100%;line-height: 12px;"><mat-icon aria-hidden="false">warning</mat-icon> 
    <p> DEBE ADJUNTAR TODA LA DOCUMENTACIÓN ANTES DE FINALIZAR SU INSCRIPCIÓN. CASO CONTRARIO SERÁ MOTIVO DE EXCLUSIÓN</p>
    <p> (Para postulantes de superior que comiencen sus cursadas en abril, deberán colocar en la siguiente pantalla la observación de presentación</p>
    <p>  extemporánea de su Certificado de Alumno Regular)</p>
    </button>
  <div>
    <h2>Lista de Archivos Cargados</h2>
    <mat-list-item *ngFor="let F of FilesLoaded">
      <mat-icon matListItemIcon>note</mat-icon>
      <div matListItemTitle>{{F}}</div>
    </mat-list-item>
    <hr>
    <br>
    <p class="sub-titulo">Cargar Nuevos Archivos</p>
    <input type="file" class="btn-upload" multiple #fileInput (change)="selectFiles(fileInput.files)" name="file">
    <br>
      <mat-nav-list>
        <mat-list-item novalidate *ngFor="let f of files">
            <a matLine>{{ f.name }}</a>
            <button  mat-icon-button (click)="click_remove(f)">
              <mat-icon>backspace</mat-icon>
            </button>
        </mat-list-item>
      </mat-nav-list>
      <button mat-fab color="primary" (click)="uploadFile()" [disabled]="files.length === 0">
        <mat-icon>backup</mat-icon>
      </button>
  </div>
</div>
