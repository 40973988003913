import { ReportDto } from './../interfaces/reportDto';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { RestApiService } from "./restApi.service";
import { retry, catchError,map } from 'rxjs/operators';
import { Inscripcion } from './../interfaces/Inscripcion';
@Injectable({
  providedIn: 'root'
})
export class ReportService extends RestApiService{
  getReport(report:ReportDto): Observable<any> {
    return this.http.post<any>(this.apiURL + '/Report/GetReport', JSON.stringify(report), this.httpOptions)
    .pipe(
      map(res => {
        return res;
      }),
      retry(0),
      catchError(this.handleError)
    )
  }
}
