import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../api/login.service';
import { LocalStorageService } from "../common/localStorage.service";
import { activationDTO } from '../interfaces/activationDTO';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.css']
})
export class ActivationComponent implements OnInit {

  valueComponent:string = "comp_gratulation";
  keyComponent:string = "key_component";
  keyGratulation:string="key_gratulation";
  msj:string = "";
  scrHeight: number;
  scrWidth: number;
  activation: activationDTO;

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.scrHeight = window.innerHeight- 240;
          this.scrWidth = window.innerWidth - 80;
    }


  constructor(private localStorageService:LocalStorageService,
    private loginService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.getScreenSize();
    //const box = document.getElementsByClassName('asd');
    const boxes = Array.from(document.getElementsByClassName('contenedor') as HTMLCollectionOf<HTMLElement>)

    for (const box of boxes) {
      box.style.display = 'none';
    }

    const items = Array.from(document.getElementsByClassName('pagewrouter') as HTMLCollectionOf<HTMLElement>)

    for (const box of items) {
      box.style.display = 'flex';
      box.style.width = this.scrWidth.toString()+"px";
      box.style.height = this.scrHeight.toString()+"px";
    }

    const items2 = Array.from(document.getElementsByClassName('activationcomp') as HTMLCollectionOf<HTMLElement>)

    for (const box of items2) {
      box.style.width = (this.scrWidth - 100).toString()+"px";
      box.style.height = (this.scrHeight - 400).toString()+"px";
    }

    


    this.activatedRoute.queryParams.subscribe((params) => {
      debugger

      this.activation = {
        userid:"",code:""
      };

      this.activation.userid = params['userid'];
      this.activation.code = params['code'];

      this.loginService.Activation(this.activation).subscribe(x => {
        debugger
        if (x.success)
        {
          const items = Array.from(document.getElementsByClassName('activationcomp') as HTMLCollectionOf<HTMLElement>)
          for (const box of items) {
            box.innerHTML = x.response;
          }
        }
        else{
          console.log(x.response)
          const items = Array.from(document.getElementsByClassName('activationcomp') as HTMLCollectionOf<HTMLElement>)
          for (const box of items) {
            box.innerHTML = "Ocurrio un error intentado activar su cuenta por favor informe el error.";
          }
        }
        
      });

      try{
 
       } 
      catch (err) 
      {
        debugger
        console.log(err)
      }
    });

  }

  Volver(){
    window.location.href = "/"
  }

}
