import { Routes, RouterModule } from "@angular/router";
import { Injectable, ModuleWithProviders } from "@angular/core";
import { RouteInfo, MenuType } from "./router.metadata";

import { ActivationComponent } from "../activation/activation.component";
import { AppComponent } from "../app.component";


const ROUTES: RouteInfo[] = [
  {
    path: "activation",
    component: ActivationComponent, title: "home", menuType: MenuType.LEFT
  },
{
  path: "",
  component: AppComponent, menuType: MenuType.RIGHT, title: "Admin", pathMatch: "full"
},
];

export const APP_ROUTES: ModuleWithProviders<any> = RouterModule.forRoot([
  {
    path: "activation",
    component: ActivationComponent
  },

  {
    path: "",
    component: AppComponent, pathMatch: "full"
  },
  

], { enableTracing: false } );

@Injectable()
export class RouterService {

  public getRoutes(): RouteInfo[] {
    return ROUTES;
  }
}
