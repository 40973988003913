import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { Pais } from '@interfaces/pais';

import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class PaisService extends RestApiService {
  getAll(): Observable<Pais[]> {
    return this.http.get<Pais[]>(this.apiURL + '/pais')
    .pipe(
      map(res => {return res;}),
      retry(1),
      catchError(this.handleError)
    )
  }

  getPaisByProvincia(provinciaid): Observable<Pais> {
    return this.http.get<Pais>(this.apiURL + '/pais/getPaisByProvincia/'+provinciaid)
    .pipe(
      map(res => {return res;}),
      retry(0),
      catchError(this.handleError)
    )
  }


}
