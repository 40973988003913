<h2>Regenerar Password</h2>
<ngb-alert #alerterror *ngIf="msgerror"  style="color: red;" (close)="msgerror=''">
  {{ msgerror }}
</ngb-alert>
<br>
<ngb-alert #alertsucces *ngIf="msgsucces"  style="color: green;" (close)="msgsucces=''">
  {{ msgsucces }}
</ngb-alert>
<mat-form-field class="full-width-input" style="width: 100%;">
  <input matInput placeholder="Email" [(ngModel)]="login.userName" style="text-transform: none !important;"   required>
</mat-form-field>
<mat-progress-spinner mode="indeterminate" *ngIf="showSpinner" style="left: 50%; position: absolute; text-align: center; top: 50%; background-color: white;margin-top: -4%;"> </mat-progress-spinner>
<br>
<br>
<button mat-raised-button color="primary" style="width: 100%;" (click)="Resetpassword()">Regenerar Password</button>
<br>
<br>
<button mat-raised-button color="warn" style="width: 100%;" (click)="Close()">Volver</button>
<br>
<br>