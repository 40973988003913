<h2 mat-dialog-title>
	DATOS DE ESTUDIANTE
</h2>

<mat-dialog-content>
	<form [formGroup]="form" autocomplete="off">
		<br>
		<mat-card class="mat-elevation-z8">
			<mat-card-content>

				<mat-form-field class="example-full-width">
					<mat-label>¿Es beneficiario de Becas Académicas Provinciales 2023? <span> * </span></mat-label>
					<mat-select id="beneficiarioanterior" [(value)]="beneficiarioanterior" formControlName="beneficiarioanterior">
						<mat-option [value]="condicional.status" *ngFor="let condicional of condicionales">{{ condicional.nombre }}</mat-option>
					</mat-select>
				</mat-form-field>
				
				<mat-form-field class="example-full-width">
					<mat-label>Nombre <span> * </span></mat-label>
					<input type="text" #nombre maxlength="50" matInput formControlName="nombre" />
					<mat-hint align="end">{{nombre.value?.length || 0}}/50</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Apellido <span> * </span></mat-label>
					<input type="text" #apellido maxlength="50" matInput formControlName="apellido" />
					<mat-hint align="end">{{apellido.value?.length || 0}}/50</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Documento <span> * </span></mat-label>
					<input type="number" #documento maxlength="50" matInput formControlName="documento" />
					<mat-hint align="end">{{documento.value?.length || 0}}/8</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Cuil <span> * </span></mat-label>
					<input type="number" #cuil maxlength="50" matInput formControlName="cuil" />
					<mat-hint align="end">{{cuil.value?.length || 0}}/13</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>FechaNacimiento <span> * </span></mat-label>
					<input matInput [matDatepicker]="picker" formControlName="fechaNacimiento">
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Lugar Nacimiento <span> * </span></mat-label>
					<input type="text" #lugarNacimiento maxlength="50" matInput formControlName="lugarNacimiento" />
					<mat-hint align="end">{{lugarNacimiento.value?.length || 0}}/100</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Domicilio en TDF<span> * </span></mat-label>
					<input type="text" #domicilio maxlength="50" matInput formControlName="domicilio" />
					<mat-hint align="end">{{domicilio.value?.length || 0}}/80</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Nacionalidad <span> * </span></mat-label>
					<input type="text" #nacionalidad maxlength="50" matInput formControlName="nacionalidad" />
					<mat-hint align="end">{{nacionalidad.value?.length || 0}}/80</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Pais <span> * </span></mat-label>
					<mat-select id="pais" (selectionChange)="selectPais($event.value)">
						<mat-option [value]="pais" *ngFor="let pais of paises">{{ pais.nombre }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Provincia en la que estudia<span> * </span></mat-label>
					<mat-select id="provincia" (selectionChange)="selectProvincia($event.value)">
						<mat-option [value]="provincia" *ngFor="let provincia of provincias">{{ provincia.nombre }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Ciudad <span> * </span></mat-label>
					<mat-select id="ciudad" formControlName="ciudad" (selectionChange)="selectCiudad($event.value)">
						<mat-option [value]="ciudad" *ngFor="let ciudad of ciudades">{{ ciudad.nombre }}</mat-option>
					</mat-select>
				</mat-form-field>
				
				<mat-form-field class="example-full-width">
					<mat-label>Estudia fuera de la Provincia? <span> * </span></mat-label>
					<mat-select id="estudiafueraprovincia" [(value)]="estudiafueraprovincia" formControlName="estudiafueraprovincia" (selectionChange)="enabledloacalidad($event.value)">
						<mat-option [value]="condicionale.status" *ngFor="let condicionale of condicionales">{{ condicionale.nombre }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Estudia Distancia? <span> * </span></mat-label>
					<mat-select id="estudiaDistancia" [(value)]="estudiaDistancia" formControlName="estudiaDistancia" (selectionChange)="enabledEscuela($event.value)">
						<mat-option [value]="condicionale.status" *ngFor="let condicionale of condicionales">{{ condicionale.nombre }}</mat-option>
					</mat-select>
				</mat-form-field>
				
				<mat-form-field class="example-full-width">
					<mat-label>Años de Residencia <span> * </span></mat-label>
					<input type="number" #añosResidencia maxlength="50" matInput formControlName="añosResidencia" />
				</mat-form-field>

        <mat-form-field class="example-full-width">
					<mat-label>Trabaja? <span> * </span></mat-label>
					<mat-select id="trabaja" [(value)]="trabaja" formControlName="trabaja">
						<mat-option [value]="condicional.status" *ngFor="let condicional of condicionales">{{ condicional.nombre }}</mat-option>
					</mat-select>
				</mat-form-field>

        <mat-form-field class="example-full-width" *ngIf="trabaja">
					<mat-label>Trabajo <span> * </span></mat-label>
					<mat-select id="trabajo" formControlName="trabajo">
						<mat-option [value]="trabajo" *ngFor="let trabajo of trabajos">{{ trabajo.nombre }}</mat-option>
					</mat-select>
				</mat-form-field>

        <mat-form-field class="example-full-width" *ngIf="trabaja">
					<mat-label>Sueldo <span> * </span></mat-label>
					<input type="number" #sueldo matInput formControlName="sueldo" />
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Obra Social <span> * </span></mat-label>
					<input type="text" #obraSocial maxlength="50" matInput formControlName="obraSocial" />
					<mat-hint align="end">{{obraSocial.value?.length || 0}}/50</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Posee otro beneficio? <span> * </span></mat-label>
					<mat-select id="condicional" [(value)]="condicional" (selectionChange)="selectbeneficiario($event.value)">
						<mat-option [value]="condicional" *ngFor="let condicional of condicionales">{{ condicional.nombre }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="example-full-width" *ngIf="condicional?.nombre == 'SI'">
					<mat-label>Beneficio <span> * </span></mat-label>
					<input type="text" #beneficio matInput formControlName="beneficio" />
				</mat-form-field>

			</mat-card-content>
		</mat-card>
		<br>
		<mat-card class="mat-elevation-z8">
			<mat-card-content>
				<mat-form-field class="example-full-width">
					<mat-label>Institución  <span> * </span></mat-label>
					<mat-select id="escuela" formControlName="escuela"  (selectionChange)="selectEscuela($event.value)">
						<mat-option [value]="escuela" *ngFor="let escuela of escuelas">{{ escuela.nombre }}</mat-option>
					</mat-select>
				</mat-form-field>

        <mat-form-field class="example-full-width" *ngIf="enableOtraEscuela">
					<mat-label>Otra Institución <span> * </span></mat-label>
					<input type="text" #otraEscuela matInput formControlName="otraEscuela" />
				</mat-form-field>

        <mat-form-field class="example-full-width">
					<mat-label>Año de Estudio <span> * </span></mat-label>
					<input type="number" #añosEstudios matInput formControlName="añosEstudios" />
				</mat-form-field>

			</mat-card-content>
		</mat-card>
		<br>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-raised-button color="primary" [disabled]="!this.form.valid" [mat-dialog-close]="getFormValue()">
		Aceptar
	</button>
	<button mat-raised-button color="warn" mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
