<h2 mat-dialog-title>
	DATOS FAMILIAR
</h2>

<mat-dialog-content>
	<form [formGroup]="form" autocomplete="off">
		<br>
		<mat-card class="mat-elevation-z8">
			<mat-card-content>
				<mat-form-field class="example-full-width">
					<mat-label>Nombre <span> * </span></mat-label>
					<input type="text" #nombre maxlength="50" matInput formControlName="nombre" />
					<mat-hint align="end">{{nombre.value?.length || 0}}/50</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Apellido <span> * </span></mat-label>
					<input type="text" #apellido maxlength="50" matInput formControlName="apellido" />
					<mat-hint align="end">{{apellido.value?.length || 0}}/50</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Documento <span> * </span></mat-label>
					<input type="text" #documento maxlength="50" matInput formControlName="documento" />
					<mat-hint align="end">{{documento.value?.length || 0}}/8</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Edad <span> * </span></mat-label>
					<input type="number" #edad matInput formControlName="edad" />
				</mat-form-field>

        <mat-form-field class="example-full-width">
					<mat-label>Vinculo <span> * </span></mat-label>
					<mat-select id="vinculo" formControlName="vinculo">
						<mat-option [value]="vinculo" *ngFor="let vinculo of vinculos">{{ vinculo }}</mat-option>
					</mat-select>
				</mat-form-field>

        <mat-form-field class="example-full-width">
					<mat-label>Nivel Educativo <span> * </span></mat-label>
					<mat-select id="nivelEducativo" formControlName="nivelEducativo">
						<mat-option [value]="nivelEducativo" *ngFor="let nivelEducativo of nivelesEducativos">{{ nivelEducativo.nombre }}</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Trabajo (Indicar lugar de trabajo) <span> * </span></mat-label>
					<input type="text" #trabajo maxlength="50" matInput formControlName="trabajo" />
					<mat-hint align="end">{{trabajo.value?.length || 0}}/50</mat-hint>
				</mat-form-field>
			</mat-card-content>
		</mat-card>
		<br>
		<mat-card class="mat-elevation-z8">
			<mat-card-content>
				<mat-form-field class="example-full-width">
					<mat-label>Posee Alguna enfermedad<span> * </span></mat-label>
					<mat-select id="enfermedad" formControlName="enfermedad" (selectionChange)="selectCondicionEnfermedad($event.value)">
						<mat-option [value]=true>Si</mat-option>
						<mat-option [value]=false>No</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field class="example-full-width" *ngIf="enableEnfermedad">
					<mat-label>Cual? <span> * </span></mat-label>
					<input type="text" #descripcionEnfermedad maxlength="50" matInput formControlName="descripcionEnfermedad" />
					<mat-hint align="end">{{descripcionEnfermedad.value?.length || 0}}/100</mat-hint>
				</mat-form-field>

				<mat-form-field class="example-full-width">
					<mat-label>Posee Alguna Discapacidad<span> * </span></mat-label>
					<mat-select id="discapacidad" formControlName="discapacidad" (selectionChange)="selectCondicionDiscapacidad($event.value)">
						<mat-option [value]=true>Si</mat-option>
						<mat-option [value]=false>No</mat-option>
					</mat-select>
				</mat-form-field>

        <mat-form-field class="example-full-width" *ngIf="enableDiscapacidad">
					<mat-label>Cual? <span> * </span></mat-label>
					<input type="text" #descripcionDiscapacidad maxlength="50" matInput formControlName="descripcionDiscapacidad" />
					<mat-hint align="end">{{descripcionDiscapacidad.value?.length || 0}}/100</mat-hint>
				</mat-form-field>
			</mat-card-content>
		</mat-card>
		<br>
		<mat-card class="mat-elevation-z8">
			<mat-card-content>
				<mat-form-field class="example-full-width">
					<mat-label>Ingreso <span> * </span></mat-label>
					<input type="number" #ingreso matInput formControlName="ingreso" />
				</mat-form-field>
			</mat-card-content>
		</mat-card>
		<br>
	</form>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-raised-button color="primary" [disabled]="!this.form.valid" [mat-dialog-close]="getFormValue()">
		Aceptar
	</button>
	<button mat-raised-button color="warn" mat-dialog-close>Cancelar</button>
</mat-dialog-actions>
