import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class ComentarioService extends RestApiService {

}
