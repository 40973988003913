import { LoginDto } from './../interfaces/loginDto';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { RestApiService } from "./restApi.service";
import { retry, catchError,map } from 'rxjs/operators';
import { RegisterDTO } from '../interfaces/RegisterDTO';
import { activationDTO } from '../interfaces/activationDTO';
@Injectable({
  providedIn: 'root'
})
export class LoginService extends RestApiService{
  validate(login:LoginDto): Observable<any> {
    return this.http.post<any>(this.apiURL + '/User/Validate', JSON.stringify(login), this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  LoginAdmin(login:LoginDto): Observable<any> {
    return this.http.post<any>(this.apiURL + '/User/Adminlogin', JSON.stringify(login), this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  Register(register:RegisterDTO): Observable<any> {
    return this.http.post<any>(this.apiURL + '/User/Register', JSON.stringify(register), this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  Activation(activation:activationDTO): Observable<any> {
    return this.http.post<any>(this.apiURL + '/User/Activation', JSON.stringify(activation), this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }

  ResetPassword(login:LoginDto): Observable<any> {
    return this.http.post<any>(this.apiURL + '/User/ResetPassword', JSON.stringify(login), this.httpOptions)
    .pipe(
      catchError(this.handleError)
    )
  }


}
