import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { CompaniaTelefonica } from '@interfaces/companiaTelefonica';

import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class CompaniaTelefonicaService extends RestApiService {
  getAll(): Observable<CompaniaTelefonica[]> {
    return this.http.get<CompaniaTelefonica[]>(this.apiURL + '/CompaniaTelefonica')
    .pipe(
      map(res => {return res;}),
      retry(1),
      catchError(this.handleError)
    )
  }
}
