import { LoginDto } from './../interfaces/loginDto';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from "@api/login.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  public loginInvalid = false;
  public loginUp = false;
  private returnUrl: string;
  constructor(
    private fb: FormBuilder,
    private loginService: LoginService)
    {
      this.form = this.fb.group({
        username: ['', Validators.email],
        password: ['', Validators.required]
      });
    }

  ngOnInit(): void {
  }
  onSubmit(): void {
    this.loginInvalid = false;
    if (this.form.valid) {
      try {
        var login = this.getFormValue();
        this.loginService.LoginAdmin(login).subscribe(x => {
          if (x.data){
            localStorage.setItem('key_login', JSON.stringify(x.data));
            this.loginUp = true;
            this.loginInvalid = false;
          }
          else{
            this.loginUp = false;
            this.loginInvalid = true;
          }
        });
      } catch (err) {
        this.loginInvalid = true;
        this.loginUp= false;
      }
    }
    else
    this.loginUp= false;
  }

  getFormValue(): LoginDto {

		return {
			...this.form.value
		};
	}
}
