import { TutorComponent } from './tutor/tutor.component';
import { PostulanteComponent } from './postulante/postulante.component';
import { GratulationComponent } from './gratulation/gratulation.component';
import { EstudianteOutsideComponent } from './estudianteoutside/estudianteoutside.component';
import { ComentarioComponent } from './comentario/comentario.component';
import { GrupoFamiliarComponent } from './grupofamiliar/grupofamiliar.component';
import { Component, ViewChild, ComponentFactoryResolver, OnInit } from '@angular/core';
import { DynamicHostDirective } from './directive/dynamic-host.directive';
import { ArrayRouting } from './common/arrayRouting';
import { LocalStorageService } from "./common/localStorage.service";
import { ContactoComponent } from './contacto/contacto.component';
import { SocioEconomicoComponent } from "../app/socioeconomico/socioeconomico.component";
import { DialogMensajeComponent } from "../app/dialogmensaje/dialogmensaje.component";
import { TerminosComponent } from "../app/terminos/terminos.component";
import { MatDialog } from '@angular/material/dialog';
import {InscripcionService} from "@api/inscripcion.service";
import {Stage} from "@interfaces/stage";
import { HostListener } from "@angular/core";
import { UriImage } from './common/UriImage';
import { LoginComponent } from './login/login.component';
import { RealLoginComponent } from './RealLogin/Reallogin.component';
import { Route, Router } from '@angular/router';
import { UploadComponent } from './upload/upload.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit   {
  title = 'Inscripciones';
  keyComponent:string = "key_component";
  showSpinner:boolean = false;
  descripcionFooter:string=' ';
  
  scrHeight:any;
  scrWidth:any;
  @ViewChild(DynamicHostDirective) public dynamicHost: DynamicHostDirective;

  isPreview: boolean = false;
  isNext: boolean = false;
  isGratulation: boolean = false;
  isPresentation: boolean = false;
  isShowMessage:boolean = true;

  labelbutton:string = "Siguiente";
  syleclass: boolean=false;

  stepCurrent : any;
  componetCurrent : any;
  stage: Stage;
  uri:string = UriImage[0].uri;

  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.scrHeight = window.innerHeight;
          this.scrWidth = window.innerWidth;
          if (this.scrWidth > 1023)
            this.uri = UriImage[0].uri;
          if (this.scrWidth < 1023)
            this.uri = UriImage[1].uri;
    }

  constructor(
    private componentFactoryResolver:ComponentFactoryResolver,
    private localStorageService:LocalStorageService,
    private matDialog:MatDialog,
    private inscripcionService:InscripcionService,
    private router: Router
    )
    {this.getScreenSize();}

  ngOnInit() {
    this.descripcionFooter = new Date().getFullYear().toString() + ' GOBIERNO DE TIERRA DEL FUEGO A.E.I.A.S.';
    
    debugger
    this.inscripcionService.stages().subscribe(resp => {
      this.stage = resp;
    });


    this.localStorageService.clear();
    this.stepCurrent = ArrayRouting[0];
  }
  onChangeValue(value:boolean){
    this.isPresentation= value;
  }
  onPresentation(){
    this.createComponent(this.stepCurrent.component);
    this.setVisibilityButton(this.stepCurrent);
  }

  onPreview(){
    debugger
    this.stepCurrent = this.getPreviewStep(this.stepCurrent);
    this.createComponent(this.stepCurrent.component);
    this.setVisibilityButton(this.stepCurrent);
  }

  getComponentCurrent():any{
    let comp = this.localStorageService.getItem(this.keyComponent);
    if (comp === undefined)
      return undefined;
      debugger
      switch (comp) {
        case "comp_login":
          return <RealLoginComponent>this.componetCurrent.instance;
        case "comp_contacto":
          return <ContactoComponent>this.componetCurrent.instance;
        case "comp_postulante":
          return <PostulanteComponent>this.componetCurrent.instance;
        case "comp_tutor":
          return <TutorComponent>this.componetCurrent.instance;
        case "comp_grupofamiliar":
          return <GrupoFamiliarComponent>this.componetCurrent.instance;
        case "comp_socioeconomico":
          return <SocioEconomicoComponent>this.componetCurrent.instance;
        case "comp_estudianteoutside":
          return <EstudianteOutsideComponent>this.componetCurrent.instance;
        case "comp_comentario":
          return <ComentarioComponent>this.componetCurrent.instance;
        case "comp_gratulation":
          return <GratulationComponent>this.componetCurrent.instance;
          case "comp_uploads":
            return <UploadComponent>this.componetCurrent.instance;
        default:
          return undefined;
      }
  }
  onNext(){
    let comp_value = this.getComponentCurrent();
    debugger
    if (comp_value === undefined)
      window.location.reload();

    let user = JSON.parse(localStorage.getItem("key_login"));

    if (comp_value.valueComponent != "comp_login" && comp_value.valueComponent != "comp_gratulation" && user == null)
    {
      window.location.href = "/";
    }
    // segun component valido en el servicio
    
    this.showSpinner= true;
    comp_value.validate().subscribe(res => {
      debugger
      this.showSpinner = false;
      if (res.data){
        this.stepCurrent = this.getNextStep(this.stepCurrent);
         //si este stepCurrent es null-undefined muestro fin del formulario
        if (this.stepCurrent === undefined)
          this.createComponent(ArrayRouting[ArrayRouting.length-1].component);
       else
         this.createComponent(this.stepCurrent.component);
       this.setVisibilityButton(this.stepCurrent);
      }
      else{
        this.matDialog.open(DialogMensajeComponent,{data: res.mensaje ? res.mensaje : res});
      }
    });
  }
  onGratulation(){
    window.location.reload();
  }
  getPreviewStep(stepCurrent: any):any{
    if (stepCurrent === undefined)
      return;
    let index = ArrayRouting.findIndex(s => s.name === stepCurrent.name);
    let indexNext = index - 1;
    return ArrayRouting[indexNext];
  }

  getNextStep(stepCurrent: any):any{
    debugger
    if (stepCurrent === undefined)
      return undefined;
    let index = ArrayRouting.findIndex(s => s.name === stepCurrent.name);
    let indexNext = index + 1;

    if (ArrayRouting.length == (indexNext+2))
      this.changelabelbtn("Finalizar")
    else
      this.changelabelbtn("Siguiente")

    if (ArrayRouting.length <= (indexNext+1))
      return undefined;
    else
      return ArrayRouting[indexNext];
  }

  createComponent(stepView: any): void{
    let component = this.componentFactoryResolver.resolveComponentFactory(stepView);
    this.dynamicHost.viewContainerRef.clear();
    this.componetCurrent = this.dynamicHost.viewContainerRef.createComponent(component);
  }

  changelabelbtn(label:string)
  {

    if (label == "Finalizar")
      this.syleclass=true;
    else
      this.syleclass=false;
      
    this.labelbutton = label;
  }

  setVisibilityButton(stepCurrent: any){
    console.log(stepCurrent);
    if (stepCurrent === undefined){
      this.isPresentation = false;
      this.isNext = true;
      this.isPreview = false;
      this.isGratulation = true;
      return;
    }
    
    if (stepCurrent.name === "Login"){
      this.isShowMessage = false;
      this.isPresentation = false;
      this.isNext = true;
      this.isPreview = false;
      this.isGratulation = false;
      return;
    }


    let index = ArrayRouting.findIndex(s => s.name === stepCurrent.name);
    if (index === 0){
      this.isShowMessage = false;
      this.isPresentation = false;
      this.isNext = true;
      this.isPreview = false;
      this.isGratulation = false;
      return;
    }
    if (index >= 1)
      this.isPreview = true;
    return;
  }
  leerMas(){
    this.matDialog.open(TerminosComponent);
  }
  click_login(){
    this.matDialog.open(LoginComponent);
    }
}
