import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class RestApiService {

  // Define API
  //apiURL = 'http://localhost:12488/api';
  apiURL = 'https://becas.tierradelfuego.edu.ar/api';
  //apiURL = 'https://educacion.testing.tierradelfuego.edu.ar/api';

  constructor(public http: HttpClient) { }

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  httpOptions_form_data = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    })
  }

  handleError(error) {
    debugger
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
 }
}
