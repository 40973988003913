<header>
  <img class="image" [src]="uri">
</header>
<div class="button-container">
  <button mat-mini-fab color="primary" (click)="click_login()">
    <mat-icon>vpn_key</mat-icon>
  </button>
</div>
<!-- Contenedor para el contenido de en Medio de mi Página de la web -->
  <div  class="pagewrouter" style="display: none;">
    <router-outlet></router-outlet>
  </div>
  <div id='contenedor' class="contenedor">
   <section class="contenido_medio">
    <div *ngIf="stage?.nombre == 'stage_1'">
      <mat-progress-spinner mode="indeterminate" *ngIf="showSpinner"> </mat-progress-spinner>
      <mat-card class="example-card">
        <mat-card-header></mat-card-header>
        <mat-card-content>
          <p-scrollPanel>
            <div class="demo-container p-p-3" *ngIf="isShowMessage">
              <h2>TÉRMINOS Y CONDICIONES <h3 style="color:red">(Leer con Atención)</h3></h2>
              <h2>LLAMADO A CONCURSO PARA OBTENCIÓN DE BECAS PROVINCIALES - AÑO 2024.</h2>
              <p>Convocatoria abierta para la postulación en el Programa de Becas Académicas </p>
              <p>2024, de la Provincia de Tierra del Fuego, Antártida e Islas del Atlántico Sur. El  beneficio</p>
              <p>estádestinado paraquienes se encuentren cursando los niveles Inicial, Primario,</p>
              <p> o Secundario, en establecimientos de gestión  pública, en cualquiera de sus modalidades, en</p>
              <p>el ámbito de la Provincia. También paraquienes  se  encuentren  cursandoestudios  superiores: </p>
              <p>universitarios y  no-universitarios ...</p>
              <p><a mat-flat-button (click)="leerMas()">leer mas</a></p>
              <br>
              <br>
              <mat-checkbox value="checked" (change)="onChangeValue($event.checked)" class="example-margin">Aceptar términos y condiciones</mat-checkbox>
            </div>
            <ng-template appDynamicHost></ng-template>
          </p-scrollPanel>
        </mat-card-content>
        <mat-card-actions>
          <div class="left">
            <button mat-raised-button color="primary" (click)="onPreview()" *ngIf="isPreview">Anterior</button>
          </div>
          <div class="center">
            <button mat-raised-button color="primary" (click)="onPresentation()" *ngIf="isPresentation">Iniciar</button>
          </div>
          <div class="center">
            <button mat-raised-button color="primary" (click)="onGratulation()" *ngIf="isGratulation">realizar otra inscripcion</button>
          </div>
          <div class="right">
            <button id="btndynamic" mat-raised-button color="primary" (click)="onNext()" *ngIf="isNext && !showSpinner"  [class.button-finalizar] = "syleclass">{{labelbutton}}</button>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
    <div  *ngIf="stage?.nombre == 'stage_2'">
    <app-upload></app-upload>
    </div>
    <div  *ngIf="stage?.nombre == 'stage_3'">
      <mat-card style="background-color: rgb(112, 136, 214);">
          <h2 style="font-weight: bolder;"> 
            Inscripciones: próximamente se dará inicio al período de inscripción para el presente año.
          </h2>
      </mat-card>
    </div>
   </section> <!-- Contenido en la parte de en Medio de mi Página de la web -->

  </div>

<!-- Mi Footer o Pie de Página de la web -->
<footer>{{descripcionFooter}}</footer>









