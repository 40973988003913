  <h2>Ingreso de Usuarios</h2>
  <mat-error *ngIf="loginInvalid">
    El Usuario o la password es incorrecta.
  </mat-error>
  <mat-form-field class="full-width-input" style="width: 280px;">
    <input matInput placeholder="Email" [(ngModel)]="username"  required>
    <mat-error>
      Por Favor ingresar el Email.
    </mat-error>
  </mat-form-field>
  <mat-form-field class="full-width-input" style="width: 280px;" >
    <input matInput type="password" placeholder="Contraseña" [(ngModel)]="passwrod" required>
    <mat-error>
    Por favor ingresa la contraseña.
    </mat-error>
  </mat-form-field>

<br>
<button mat-flat-button color="primary" (click)="openDialogRegister()"><mat-icon aria-hidden="false">warning</mat-icon> <span> Si todavia no creaste tu usuario Click Aqui </span></button>
<br>
<br>
<button mat-flat-button color="warn" (click)="openDialogResetPassword()"><mat-icon aria-hidden="false">warning</mat-icon> <span> Si olvidaste tu contraseña Click Aqui </span></button>
