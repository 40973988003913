<div>
   <button mat-flat-button color="warn" style="width: 100%;"><mat-icon aria-hidden="false">warning</mat-icon> <span> Antes de Finalizar corrobore que los datos ingresados sean correctos y reales. <br> Una vez finalizada la inscripción no puede editar los registros.</span></button>
   <br>
   <br>
   <p class="sub-titulo">Comente en este espacio alguna situacion que desee aclarar</p>
   <form [formGroup]="form" class="form-container">

      <mat-form-field class="example-full-width">
         <mat-label>Comentario </mat-label>
         <textarea #comentario maxlength="250" matInput formControlName="comentario"></textarea>
         <mat-hint align="end">{{comentario.value?.length || 0}}/250</mat-hint>
      </mat-form-field>
   </form>
</div>