import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { SocioEconomico } from '@interfaces/socioEconomico';

import { RestApiService } from "./restApi.service";

@Injectable({
  providedIn: 'root'
})

export class SocioEconomicoService extends RestApiService {

  validate(entity:SocioEconomico,userid:string): Observable<any> {
    entity.tipoServicio = JSON.stringify(entity.tipoServicio);
    return this.http.post<any>(this.apiURL + '/socioEconomico/Validate/'+userid, JSON.stringify(entity), this.httpOptions)
    .pipe(
      map(res => {return res;}),
      catchError(this.handleError)
    )
  }


  getSocioEconomicoByUser(userid:string): Observable<any> {
    return this.http.get<any>(this.apiURL + '/socioEconomico/GetByUserId/'+userid)
    .pipe(
      map(resp => 
        {
          return resp.data
        }),
      catchError(this.handleError)
    )
  }

}
